import { type ForwardedRef, forwardRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useEventCallback } from '..';
import usePortalElement from './usePortalElement';

/** Props for {@link PortalInner}. */
type PortalInnerProps = {
	/** Primary content. */
	children: JSX.Element;

	/** The node where the portal should mount. */
	mountNode?: HTMLElement;

	/** Called when the PortalInner is mounted on the DOM. */
	onMount?: (nothing: null, data: PortalInnerProps) => void;

	/** Called when the PortalInner is unmounted from the DOM. */
	onUnmount?: (nothing: null, data: PortalInnerProps) => void;
};

/** An inner component that allows you to render children outside their parent. */
export const PortalInner = forwardRef(function PortalInner(props: PortalInnerProps, ref: ForwardedRef<HTMLElement>) {
	const handleMount = useEventCallback(() => props.onMount?.(null, props));
	const handleUnmount = useEventCallback(() => props.onUnmount?.(null, props));

	const element = usePortalElement(props.children, ref);

	useEffect(() => {
		handleMount();

		return () => {
			handleUnmount();
		};
	}, [handleMount, handleUnmount]);

	return createPortal(element, props.mountNode || document.body);
});
