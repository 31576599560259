import clsx from 'clsx';
import * as _ from 'es-toolkit/compat';
import {
	type ComponentPropsWithoutRef,
	createElement,
	type ElementType,
	type ForwardedRef,
	forwardRef,
	type ReactNode
} from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { Checkbox } from '../Checkbox';
import type { HtmlLabelProps, SemanticShorthandContent, SemanticShorthandItem, SemanticWIDTHS } from '../Generic';
import { createLabel, type LabelProps } from '../Label/Label';
import { childrenUtils, createHTMLLabel, getComponentType, getUnhandledProps, keyOnly, widthProp } from '../lib';
import { Radio } from '../Radio';

/** Props for {@link BasicFormField}. */
export type BasicFormFieldProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/**
	 * A form control component (i.e. Dropdown) or HTML tagName (i.e. 'input'). Extra FormField props are passed to the
	 * control component. Mutually exclusive with children.
	 */
	control?: ElementType;

	/** Individual fields may be disabled. */
	disabled?: boolean;

	/** Individual fields may display an error state along with a message. */
	error?: boolean | SemanticShorthandItem<LabelProps>;

	/** The id of the control */
	id?: string;

	/** A field can have its label next to instead of above it. */
	inline?: boolean;

	/** Mutually exclusive with children. */
	label?: SemanticShorthandItem<HtmlLabelProps>;

	/** A field can show that input is mandatory. Requires a label. */
	required?: boolean;

	/** Passed to the control component (i.e. <input type='password' />) */
	type?: string;

	/** A field can specify its width in grid columns */
	width?: SemanticWIDTHS;
};

/** Props for {@link FormField}. */
export type FormFieldProps = ExtendTypeWith<ComponentPropsWithoutRef<'div'>, BasicFormFieldProps>;

/**
 * A field is a form element containing a label and an input.
 *
 * @see Form
 * @see Button
 * @see Checkbox
 * @see Dropdown
 * @see Input
 * @see Radio
 * @see Select
 */
export const FormField = forwardRef(function FormField(props: FormFieldProps, ref: ForwardedRef<HTMLElement>) {
	const { children, className, content, control, disabled, error, inline, label, required, type, width, id } = props;

	const classes = clsx(
		keyOnly(disabled, 'disabled'),
		keyOnly(error, 'error'),
		keyOnly(inline, 'inline'),
		keyOnly(required, 'required'),
		widthProp(width, 'wide'),
		'field',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	const errorPointing = _.get(error, 'pointing', 'above');
	const errorLabel = createLabel(error, {
		autoGenerateKey: false,
		defaultProps: {
			prompt: true,
			pointing: errorPointing,
			id: id ? `${id}-error-message` : undefined,
			role: 'alert',
			'aria-atomic': true
		}
	});

	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	const errorLabelBefore = (errorPointing === 'below' || errorPointing === 'right') && errorLabel;
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	const errorLabelAfter = (errorPointing === 'above' || errorPointing === 'left') && errorLabel;

	// ----------------------------------------
	// No Control
	// ----------------------------------------

	if (control == null) {
		if (label == null) {
			return (
				<ElementType {...rest} className={classes} id={id} ref={ref}>
					{childrenUtils.isNil(children) ? content : children}
				</ElementType>
			);
		}

		return (
			<ElementType {...rest} className={classes} id={id} ref={ref}>
				{errorLabelBefore}
				{createHTMLLabel(label, { autoGenerateKey: false })}
				{errorLabelAfter}
			</ElementType>
		);
	}

	// ----------------------------------------
	// Checkbox/Radio Control
	// ----------------------------------------

	const ariaDescribedBy = id && error ? `${id}-error-message` : null;
	const ariaAttrs = {
		'aria-describedby': ariaDescribedBy,
		'aria-invalid': error ? true : undefined
	};
	const controlProps = { ...rest, content, children, disabled, required, type, id, ref };

	// wrap HTML checkboxes/radios in the label
	if (control === 'input' && (type === 'checkbox' || type === 'radio')) {
		return (
			<ElementType className={classes}>
				<label>
					{errorLabelBefore}
					{/* FP: https://github.com/facebook/react/issues/31357 */}
					{/* eslint-disable-next-line react-compiler/react-compiler */}
					{createElement(control, { ...ariaAttrs, ...controlProps })} {label as ReactNode}
					{errorLabelAfter}
				</label>
			</ElementType>
		);
	}

	// pass label prop to controls that support it
	if (control === Checkbox || control === Radio) {
		return (
			<ElementType className={classes}>
				{errorLabelBefore}
				{/* FP: https://github.com/facebook/react/issues/31357 */}
				{/* eslint-disable-next-line react-compiler/react-compiler */}
				{createElement(control, { ...ariaAttrs, ...controlProps, label })}
				{errorLabelAfter}
			</ElementType>
		);
	}

	// ----------------------------------------
	// Other Control
	// ----------------------------------------

	return (
		<ElementType className={classes}>
			{createHTMLLabel(label, {
				defaultProps: { htmlFor: id },
				autoGenerateKey: false
			})}
			{errorLabelBefore}
			{/* FP: https://github.com/facebook/react/issues/31357 */}
			{/* eslint-disable-next-line react-compiler/react-compiler */}
			{createElement(control, { ...ariaAttrs, ...controlProps })}
			{errorLabelAfter}
		</ElementType>
	);
});
const handledProps = [
	'as',
	'children',
	'className',
	'content',
	'control',
	'disabled',
	'error',
	'id',
	'inline',
	'label',
	'required',
	'type',
	'width'
];
