import { type ForwardedRef, forwardRef, type JSX } from 'react';
import { ClipboardUtils } from 'ts/commons/ClipboardUtils';
import { tsdom } from 'ts/commons/tsdom';

type ClipboardButtonProps = JSX.IntrinsicElements['button'] & {
	clipboardText?: string | null;
	clipboardTarget?: string;
};

/**
 * A copy-to-clipboard component. Can be used for arbitrary components using "as={ClipboardButton}" (importing this
 * component is necessary).
 */
export const ClipboardButton = forwardRef(function ClipboardButton(
	{ clipboardText, clipboardTarget, ...props }: ClipboardButtonProps,
	ref: ForwardedRef<HTMLButtonElement>
): JSX.Element {
	return (
		<button
			ref={ref}
			{...props}
			type="button"
			onClick={event => {
				if (clipboardText == null) {
					return;
				}
				const targetSelector = clipboardTarget;
				if (targetSelector != null) {
					const value = tsdom.getValue(document.querySelector(targetSelector)!);
					void ClipboardUtils.copyTextToClipboard(value);
				} else {
					void ClipboardUtils.copyTextToClipboard(clipboardText);
				}
				event.stopPropagation();
				props.onClick?.(event);
			}}
		/>
	);
});
