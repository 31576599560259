import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ForwardedRef, forwardRef, memo, type MouseEvent } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticCOLORS, SemanticICONS } from '../Generic';
import {
	createShorthandFactory,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	useEventCallback,
	valueAndKey
} from '../lib';

export type IconSizeProp = 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive';
type IconCorner = 'bottom right' | 'top right' | 'top left' | 'bottom left';

/** Props for {@link Icon}. */
export type IconProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'i'>,
	{
		/** Formatted to appear bordered */
		bordered?: boolean;

		/** Icon can formatted to appear circular. */
		circular?: boolean;

		/** Additional classes. */
		className?: string;

		/** Color of the icon. */
		color?: SemanticCOLORS;

		/** Icons can display a smaller corner icon. */
		corner?: boolean | IconCorner;

		/** Show that the icon is inactive. */
		disabled?: boolean;

		/** Fitted, without space to left or right of Icon. */
		fitted?: boolean;

		/** Icon can be flipped. */
		flipped?: 'horizontally' | 'vertically';

		/** Formatted to have its colors inverted for contrast. */
		inverted?: boolean;

		/** Icon can be formatted as a link. */
		link?: boolean;

		/** Icon can be used as a simple loader. */
		loading?: boolean;

		/** Name of the icon. */
		name?: SemanticICONS;

		/** Icon can rotated. */
		rotated?: 'clockwise' | 'counterclockwise';

		/** Size of the icon. */
		size?: IconSizeProp;

		/** Icon can have an aria hidden. */
		'aria-hidden'?: string;

		/** Icon can have an aria label. */
		'aria-label'?: string;
	}
>;

function getAriaProps(props: IconProps) {
	const ariaOptions: Record<string, string> = {};
	const { 'aria-label': ariaLabel, 'aria-hidden': ariaHidden } = props;

	if (ariaLabel == null) {
		ariaOptions['aria-hidden'] = 'true';
	} else {
		ariaOptions['aria-label'] = ariaLabel;
	}

	if (ariaHidden != null) {
		ariaOptions['aria-hidden'] = ariaHidden;
	}

	return ariaOptions;
}

/**
 * An icon is a glyph used to represent something else.
 *
 * @see Image
 */
const Icon = forwardRef(function Icon(props: IconProps, ref: ForwardedRef<HTMLElement>) {
	const {
		bordered,
		circular,
		className,
		color,
		corner,
		disabled,
		fitted,
		flipped,
		inverted,
		link,
		loading,
		name,
		rotated,
		size
	} = props;

	const classes = clsx(
		color,
		name,
		size,
		keyOnly(bordered, 'bordered'),
		keyOnly(circular, 'circular'),
		keyOnly(disabled, 'disabled'),
		keyOnly(fitted, 'fitted'),
		keyOnly(inverted, 'inverted'),
		keyOnly(link, 'link'),
		keyOnly(loading, 'loading'),
		keyOrValueAndKey(corner, 'corner'),
		valueAndKey(flipped, 'flipped'),
		valueAndKey(rotated, 'rotated'),
		'icon',
		className
	);

	const rest = getUnhandledProps(handledProps, props);
	const ariaProps = getAriaProps(props);

	const handleClick = useEventCallback((e: MouseEvent<HTMLElement>) => {
		if (disabled) {
			e.preventDefault();
			return;
		}

		props.onClick?.(e);
	});

	return <i {...rest} {...ariaProps} className={classes} onClick={handleClick} ref={ref} />;
});

// Heads up!
// .create() factories should be defined on exported component to be visible as static properties
const MemoIcon = memo(Icon);

export const createIcon = createShorthandFactory<typeof MemoIcon, SemanticICONS>(MemoIcon, value => ({
	name: value
}));

export { MemoIcon as Icon };
const handledProps = [
	'aria-hidden',
	'aria-label',
	'as',
	'bordered',
	'circular',
	'className',
	'color',
	'corner',
	'disabled',
	'fitted',
	'flipped',
	'inverted',
	'link',
	'loading',
	'name',
	'rotated',
	'size'
];
