import { linkTo } from 'ts/commons/links/LinkTo';
import { ERequirementsPerspectiveView } from 'ts/perspectives/requirement_tracing/ERequirementsPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Returns a URL for a spec item query. */
export function specItemQuery(
	project: string,
	options?: { query?: string; queryName?: string; specItemTab?: 0 | 1 | 2 | 3 }
) {
	return linkTo(ETeamscalePerspective.REQUIREMENTS_TRACING, ERequirementsPerspectiveView.OVERVIEW, project, {
		q: options?.query,
		queryname: options?.queryName,
		specItemTab: options?.specItemTab
	});
}
