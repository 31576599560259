import type { JSX } from 'react';
import { QUERY, useQueryOperation } from 'api/Query';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { DEFAULT_STALE_TIME } from 'ts/base/perspective/topbar/warnings/GlobalWarnings';

/** Warns the user about usage of an in-memory database. */
export function useInMemoryWarning(): JSX.Element | null {
	const { data: showInMemoryWarning } = useQueryOperation(QUERY.showInMemoryWarning(), {
		staleTime: DEFAULT_STALE_TIME,
		refetchOnReconnect: 'always'
	});

	if (!showInMemoryWarning) {
		return null;
	}
	return (
		<GlobalWarning
			message="Non-persistent DB configured."
			tooltip={
				'You are currently using an in-memory database. ' +
				'Configuration settings and analysis results will not ' +
				'be persisted if Teamscale is shut down.'
			}
			severity="warning"
		/>
	);
}
