import clsx from 'clsx';
import {
	type ChangeEvent,
	Children,
	cloneElement,
	type ComponentPropsWithoutRef,
	type ComponentPropsWithRef,
	type ElementType,
	type ForwardedRef,
	forwardRef,
	type ReactNode
} from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { type ButtonProps, createButton } from '../Button/Button';
import type { DataAttributes, SemanticShorthandItem } from '../Generic';
import { createIcon, type IconProps } from '../Icon/Icon';
import { createLabel, type LabelProps } from '../Label/Label';

import {
	childrenUtils,
	createHTMLInput,
	createShorthandFactory,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	partitionHTMLProps,
	setRef,
	valueAndKey
} from '../lib';

/** Props for {@link Input}. */
export type InputProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'input'> & DataAttributes,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** An Input can be formatted to alert the user to an action they may perform. */
		action?: boolean | ReactNode | ButtonProps;

		/** An action can appear along side an Input on the left or right. */
		actionPosition?: 'left';

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** An Input field can show that it is disabled. */
		disabled?: boolean;

		/** An Input field can show the data contains errors. */
		error?: boolean;

		/** Take on the size of its container. */
		fluid?: boolean;

		/** An Input field can show a user is currently interacting with it. */
		focus?: boolean;

		/** Optional Icon to display inside the Input. */
		icon?: SemanticShorthandItem<IconProps>;

		/** An Icon can appear inside an Input on the left. */
		iconPosition?: 'left';

		/** Shorthand for creating the HTML Input. */
		input?: SemanticShorthandItem<ComponentPropsWithRef<'input'> & DataAttributes>;

		/** Format to appear on dark backgrounds. */
		inverted?: boolean;

		/** Optional Label to display along side the Input. */
		label?: SemanticShorthandItem<LabelProps>;

		/** A Label can appear outside an Input on the left or right. */
		labelPosition?: 'left' | 'right' | 'left corner' | 'right corner';

		/** An Icon Input field can show that it is currently loading data. */
		loading?: boolean;

		/** Called on change. */
		onChange?: (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;

		/** An Input can vary in size. */
		size?: 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive';

		/** An Input can receive focus. */
		tabIndex?: number;

		/** Transparent Input has no background. */
		transparent?: boolean;

		/** The HTML input type. */
		type?: string;
	}
>;

export type InputOnChangeData = {
	value: string;
} & InputProps;

/**
 * An Input is a field used to elicit a response from a user.
 *
 * @see Button
 * @see Form
 * @see Icon
 * @see Label
 */
export const Input = forwardRef(function Input(props: InputProps, ref: ForwardedRef<HTMLInputElement>) {
	const {
		action,
		actionPosition,
		children,
		className,
		disabled,
		error,
		fluid,
		focus,
		icon,
		iconPosition,
		input,
		inverted,
		label,
		labelPosition,
		loading,
		size,
		tabIndex,
		transparent,
		type = 'text'
	} = props;

	const computeIcon = () => {
		if (icon != null) {
			return icon;
		}

		if (loading) {
			return 'spinner';
		}
		return undefined;
	};

	const computeTabIndex = () => {
		if (tabIndex != null) {
			return tabIndex;
		}

		if (disabled) {
			return -1;
		}
		return undefined;
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;

		props.onChange?.(e, { ...props, value: newValue });
	};

	const partitionProps = () => {
		const unhandledProps = getUnhandledProps(handledProps, props);
		const [htmlInputProps, rest] = partitionHTMLProps(unhandledProps);

		return [
			{
				...htmlInputProps,
				disabled,
				type,
				tabIndex: computeTabIndex(),
				onChange: handleChange,
				ref
			},
			rest
		];
	};

	const classes = clsx(
		'ui',
		size,
		keyOnly(disabled, 'disabled'),
		keyOnly(error, 'error'),
		keyOnly(fluid, 'fluid'),
		keyOnly(focus, 'focus'),
		keyOnly(inverted, 'inverted'),
		keyOnly(loading, 'loading'),
		keyOnly(transparent, 'transparent'),
		valueAndKey(actionPosition, 'action') || keyOnly(action, 'action'),
		valueAndKey(iconPosition, 'icon') || keyOnly(icon || loading, 'icon'),
		valueAndKey(labelPosition, 'labeled') || keyOnly(label, 'labeled'),
		'input',
		className
	);
	const ElementType = getComponentType(props);
	const [htmlInputProps, rest] = partitionProps();

	// Render with children
	// ----------------------------------------
	if (!childrenUtils.isNil(children)) {
		// eslint-disable-next-line react-compiler/react-compiler
		const childElements = Children.toArray(children).map(child => {
			// @ts-ignore
			if (child.type === 'input') {
				// add htmlInputProps to the `<input />` child
				// @ts-ignore
				return cloneElement(child, {
					...htmlInputProps,
					// @ts-ignore
					...child.props,
					// @ts-ignore
					ref: c => {
						// @ts-ignore
						setRef(child.ref, c);
						setRef(ref, c);
					}
				});
			}

			return child;
		});

		return (
			<ElementType {...rest} className={classes}>
				{childElements}
			</ElementType>
		);
	}

	// Render Shorthand
	// ----------------------------------------
	const actionElement = createButton(action, { autoGenerateKey: false });
	const labelElement = createLabel(label, {
		defaultProps: {
			className: clsx(
				'label',
				// add 'left|right corner'
				labelPosition?.includes('corner') && labelPosition
			)
		},
		autoGenerateKey: false
	});

	return (
		<ElementType {...rest} className={classes}>
			{actionPosition === 'left' && actionElement}
			{labelPosition !== 'right' && labelElement}
			{/* FP: https://github.com/facebook/react/issues/31357 */}
			{/* eslint-disable-next-line react-compiler/react-compiler */}
			{createHTMLInput(input || type, { defaultProps: htmlInputProps, autoGenerateKey: false })}
			{createIcon(computeIcon(), { autoGenerateKey: false })}
			{actionPosition !== 'left' && actionElement}
			{labelPosition === 'right' && labelElement}
		</ElementType>
	);
});

export const createInput = createShorthandFactory(Input, type => ({ type }));
const handledProps = [
	'action',
	'actionPosition',
	'as',
	'children',
	'className',
	'disabled',
	'error',
	'fluid',
	'focus',
	'icon',
	'iconPosition',
	'input',
	'inverted',
	'label',
	'labelPosition',
	'loading',
	'onChange',
	'size',
	'tabIndex',
	'transparent',
	'type'
];
