import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { issueQuery } from 'ts/commons/links/ActivityLinks';
import { linkTo } from 'ts/commons/links/LinkTo';
import { specItemQuery } from 'ts/commons/links/RequirementsTracingLinks';
import { StringUtils } from 'ts/commons/StringUtils';
import { UniformPath } from 'ts/commons/UniformPath';
import { EMetricsPerspectiveView } from 'ts/perspectives/metrics/EMetricsPerspectiveView';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';
import { EResourceType, type EResourceTypeEntry } from 'typedefs/EResourceType';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { EType } from 'typedefs/EType';

const TYPE_TO_VIEW = {
	[EType.CODE.name]: EMetricsPerspectiveView.FILES,
	[EType.NON_CODE.name]: EMetricsPerspectiveView.NON_CODE,
	[EType.ARCHITECTURE.name]: EMetricsPerspectiveView.ARCHITECTURE_COMPONENTS,
	[EType.TEST_EXECUTION.name]: EMetricsPerspectiveView.TESTS,
	[EType.TEST_IMPLEMENTATION.name]: EMetricsPerspectiveView.TESTS,
	[EType.TEST_QUERY.name]: EMetricsPerspectiveView.TESTS,
	[EType.SPEC_ITEM.name]: EMetricsPerspectiveView.SPEC_ITEMS,
	[EType.SPEC_ITEM_QUERY.name]: EMetricsPerspectiveView.SPEC_ITEMS,
	[EType.ISSUE_QUERY.name]: EMetricsPerspectiveView.ISSUES
} as const;

/** @returns A URL to show a single file. */
export function showFile(
	project: string,
	uniformPath: string,
	options?: { commit?: UnresolvedCommitDescriptor | null }
) {
	return linkTo(ETeamscalePerspective.METRICS, EMetricsPerspectiveView.FILES, project, uniformPath, {
		t: options?.commit
	});
}

/** Links to a folder in the metrics perspective. */
export function showFolderMetrics(
	project: string,
	uniformPath: string,
	options?: {
		commit?: UnresolvedCommitDescriptor | null;
		visibleMetric?: string | null;
		highlightMetric?: boolean;
		thresholdProfile?: string;
		partitions?: string[] | null;
	}
) {
	const type = new UniformPath(uniformPath).type;
	let view: ViewDescriptor;
	if (uniformPath.includes('.architecture')) {
		view = EMetricsPerspectiveView.ARCHITECTURE_COMPONENTS;
	} else {
		view = TYPE_TO_VIEW[type.name] ?? EMetricsPerspectiveView.FILES;
	}
	return linkTo(ETeamscalePerspective.METRICS, view, project, uniformPath, {
		t: options?.commit,
		highlightMetric: options?.highlightMetric,
		visibleMetric: options?.visibleMetric,
		thresholdProfile: options?.thresholdProfile,
		partitions: options?.partitions
	});
}

/** Returns a URL for a test query. */
export function testQuery(
	project: string,
	query: string,
	params: {
		queryName?: string;
		commit?: UnresolvedCommitDescriptor | null;
	}
) {
	return linkTo(ETeamscalePerspective.METRICS, EMetricsPerspectiveView.TESTS, project, {
		t: params.commit,
		action: 'filter',
		q: query,
		queryname: params.queryName
	});
}

/** Returns a URL to the test detail perspective. */
export function testExecutionOrImplementation(
	project: string,
	uniformPath: string,
	commit: UnresolvedCommitDescriptor | CommitDescriptor | null | undefined,
	options?: { partition?: string | null }
) {
	return linkTo(ETeamscalePerspective.METRICS, EMetricsPerspectiveView.TESTS, project, uniformPath, {
		t: UnresolvedCommitDescriptor.wrap(commit),
		partition: options?.partition
	});
}

/**
 * Returns a url for navigating to a perspective showing metrics, which depends on the clicked path (e.g. code or
 * architecture).
 */
export function metrics(
	project: string,
	uniformPath: string,
	{
		commit,
		resourceType,
		visibleMetric,
		highlightMetric,
		thresholdProfile,
		partitions
	}: {
		/** The commit for which to show the metrics or null if the most recent version should be shown */
		commit?: UnresolvedCommitDescriptor | null;
		/** The active threshold profile */
		thresholdProfile?: string;
		resourceType?: EResourceTypeEntry | null;
		/** A metric (by name) that should explicitly be visible in the target view */
		visibleMetric?: string | null;
		highlightMetric?: boolean;
		partitions?: string[] | null;
	}
): string {
	const type = new UniformPath(uniformPath).type;

	if (type === EType.TEST_QUERY) {
		return linkTo(ETeamscalePerspective.METRICS, EMetricsPerspectiveView.TESTS, project, {
			action: 'filter',
			queryname: StringUtils.stripPrefix(uniformPath, EType.TEST_QUERY.prefix + '/'),
			partitions
		});
	}

	if (resourceType === EResourceType.FILE.name) {
		if (type === EType.ISSUE_QUERY) {
			return issueQuery(project, '', uniformPath);
		}

		if (type === EType.SPEC_ITEM_QUERY) {
			return specItemQuery(project, {
				queryName: uniformPath,
				specItemTab: 0
			});
		}

		if (type === EType.TEST_IMPLEMENTATION || type === EType.TEST_EXECUTION) {
			return testExecutionOrImplementation(project, uniformPath, commit);
		}

		return showFile(project, uniformPath, { commit });
	}
	return showFolderMetrics(project, uniformPath, {
		commit,
		visibleMetric,
		highlightMetric,
		partitions,
		thresholdProfile
	});
}
