import { QUERY, useSuspenseQueryOperation } from 'api/Query';
import { useContext, useMemo } from 'react';
import { UserInfoContext } from 'ts/base/context/UserInfoContext';
import type { BasicPermissionExtension } from 'ts/commons/permission/PermissionUtils';
import { EBasicPermission } from 'typedefs/EBasicPermission';
import type { EBasicPermissionScope } from 'typedefs/EBasicPermissionScope';
import type { EConfigurationFeature } from 'typedefs/EConfigurationFeature';
import type { EGlobalPermission } from 'typedefs/EGlobalPermission';
import type { EProjectPermission } from 'typedefs/EProjectPermission';
import type { PermissionLookupEBasicPermission } from 'typedefs/PermissionLookupEBasicPermission';
import type { PermissionSummary } from 'typedefs/PermissionSummary';

/** Wrapper around PermissionSummary that provides some auxiliary methods. */
export class UserPermissionInfo {
	public constructor(private readonly permissionSummary: PermissionSummary) {}

	/** Returns true if the user may access a given configuration feature. */
	public mayAccessFeature(configurationFeature: EConfigurationFeature): boolean {
		return this.permissionSummary.accessibleFeatures.includes(configurationFeature.name);
	}

	/** Returns true if the user has the given global permission. */
	public hasGlobalPermission(globalPermission: EGlobalPermission): boolean {
		return this.permissionSummary.globalPermissions.includes(globalPermission.name);
	}

	/** Returns true if the user has the project permission for the given project. */
	public hasProjectPermission(projectId: string, projectPermission: EProjectPermission): boolean {
		if (this.permissionSummary.globalProjectPermissions.includes(projectPermission.name)) {
			return true;
		}
		const projectSpecificPermissions = this.permissionSummary.projectPermissions[projectId];
		return projectSpecificPermissions?.includes(projectPermission.name) ?? false;
	}
}

/** Hook that exposes the users permissions. */
export function useUserPermissionInfo(): UserPermissionInfo {
	const permissionSummary = useContext(UserInfoContext)?.permissionSummary;
	return useMemo(() => {
		if (permissionSummary) {
			return new UserPermissionInfo(permissionSummary);
		} else {
			return new UserPermissionInfo({
				accessibleFeatures: [],
				globalPermissions: [],
				globalProjectPermissions: [],
				projectPermissions: {}
			});
		}
	}, [permissionSummary]);
}

/** Wrapper around PermissionLookup that provides some auxiliary methods. */
export class UserBasicPermissionInfo {
	public constructor(private readonly permissionLookup: PermissionLookupEBasicPermission) {}

	/** Returns true if the user has the basic permission for the given instance. */
	public hasBasicPermission(instance: string, basicPermission: EBasicPermission): boolean {
		const permissionName = basicPermission.name;
		if (this.permissionLookup.instanceGlobalPermissions.includes(permissionName)) {
			return true;
		}
		const instancePermissions = this.permissionLookup.instanceSpecificPermissions[instance];
		return instancePermissions?.includes(permissionName) ?? false;
	}

	public getPermissions(instance: string): BasicPermissionExtension {
		return {
			canEdit: this.hasBasicPermission(instance, EBasicPermission.EDIT),
			canDelete: this.hasBasicPermission(instance, EBasicPermission.DELETE),
			canEditRoles: this.hasBasicPermission(instance, EBasicPermission.EDIT_ROLES)
		};
	}
}

/** Queries the backend for the users permissions regarding a specific scope. */
export function useBasicPermissionLookup(scope: EBasicPermissionScope): UserBasicPermissionInfo {
	const permissionLookup = useSuspenseQueryOperation(QUERY.getPermissionLookup({ 'permission-scope': scope.name }));
	return new UserBasicPermissionInfo(permissionLookup);
}
