import clsx from 'clsx';
import * as _ from 'es-toolkit/compat';
import {
	type ChangeEvent,
	type ComponentPropsWithoutRef,
	type ElementType,
	type FocusEvent,
	type ForwardedRef,
	forwardRef,
	type MouseEvent,
	type ReactNode
} from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { IconProps } from 'ts/components/Icon';
import {
	ModernAutoControlledComponent,
	type ModernAutoControlledComponentState
} from 'ts/components/lib/ModernAutoControlledComponent';
import type { SemanticShorthandItem } from '../Generic';
import type { InputProps } from '../Input';
import { createInput } from '../Input/Input';
import { getComponentType, getUnhandledProps, htmlInputAttrs, keyOnly, partitionHTMLProps, valueAndKey } from '../lib';
import { SearchCategory, type SearchCategoryProps } from './SearchCategory';
import type { SearchCategoryLayoutProps } from './SearchCategoryLayout';
import { SearchResult, type SearchResultProps } from './SearchResult';
import { SearchResults } from './SearchResults';

/** Props for {@link Search}. */
export type SearchProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'input'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		// ------------------------------------
		// Behavior
		// ------------------------------------

		/** Initial value of open. */
		defaultOpen?: boolean;

		/** Initial value. */
		defaultValue?: string;

		/** Shorthand for Icon. */
		icon?: SemanticShorthandItem<IconProps>;

		/** Minimum characters to query for results. */
		minCharacters?: number;

		/** Additional text for "No Results" message with less emphasis. */
		noResultsDescription?: ReactNode;

		/** Message to display when there are no results. */
		noResultsMessage?: ReactNode;

		/** Controls whether or not the results menu is displayed. */
		open?: boolean;

		/**
		 * One of:
		 *
		 * - Array of Search.Result props e.g. `{ title: '', description: '' }` or
		 * - Object of categories e.g. `{ name: '', results: [{ title: '', description: '' }]`
		 */
		results?: SearchResultProps[] | SearchCategoryProps[];

		/** Whether the search should automatically select the first result after searching. */
		selectFirstResult?: boolean;

		/** Whether a "no results" message should be shown if no results are found. */
		showNoResults?: boolean;

		/** Current value of the search input. Creates a controlled component. */
		value?: string;

		// ------------------------------------
		// Rendering
		// ------------------------------------
		/**
		 * Renders the SearchCategory layout.
		 *
		 * @param {object} props - The SearchCategoryLayout props object.
		 * @returns {any} - Renderable SearchCategory layout.
		 */
		categoryLayoutRenderer?: (
			props: Pick<SearchCategoryLayoutProps, 'categoryContent' | 'resultsContent'>
		) => ReactNode;

		/**
		 * Renders the SearchCategory contents.
		 *
		 * @param {object} props - The SearchCategory props object.
		 * @returns {any} - Renderable SearchCategory contents.
		 */
		categoryRenderer?: (props: SearchCategoryProps) => ReactNode;

		/**
		 * Renders the SearchResult contents.
		 *
		 * @param {object} props - The SearchResult props object.
		 * @returns {any} - Renderable SearchResult contents.
		 */
		resultRenderer?: (props: SearchResultProps) => ReactNode;

		// ------------------------------------
		// Callbacks
		// ------------------------------------

		/**
		 * Called on blur.
		 *
		 * @param event - React's original SyntheticEvent.
		 * @param data - All props.
		 */
		onBlur?: (event: FocusEvent<HTMLElement>, data: SearchProps) => void;

		/**
		 * Called on focus.
		 *
		 * @param event - React's original SyntheticEvent.
		 * @param data - All props.
		 */
		onFocus?: (event: FocusEvent<HTMLElement>, data: SearchProps) => void;

		/** Called on mousedown. */
		onMouseDown?: (event: Event, data: SearchProps) => void;

		/**
		 * Called when a result is selected.
		 *
		 * @param event - React's original SyntheticEvent.
		 * @param data - All props.
		 */
		onResultSelect?: (event: Event, data: SearchResultData) => void;

		/**
		 * Called on search input change.
		 *
		 * @param event - React's original SyntheticEvent.
		 * @param data - All props, includes current value of search input.
		 */
		onSearchChange?: (event: ChangeEvent<HTMLInputElement>, data: SearchProps) => void;

		/**
		 * Called when the active selection index is changed.
		 *
		 * @param event - React's original SyntheticEvent.
		 * @param data - All props.
		 */
		onSelectionChange?: (event: Event, data: SearchResultData) => void;

		// ------------------------------------
		// Style
		// ------------------------------------

		/** A search can have its results aligned to its left or right container edge. */
		aligned?: string;

		/** A search can display results from remote content ordered by categories. */
		category?: boolean;

		/** Additional classes. */
		className?: string;

		/** A search can have its results take up the width of its container. */
		fluid?: boolean;

		/** Shorthand for input element. */
		input?: SemanticShorthandItem<InputProps>;

		/** A search can show a loading indicator. */
		loading?: boolean;

		/** A search can have different sizes. */
		size?: 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive';

		/** A search can show placeholder text when empty. */
		placeholder?: string;
	}
>;

type SearchResultData = {
	result: SearchResultProps | undefined;
} & SearchProps;

const overrideSearchInputProps = (predefinedProps: InputProps): InputProps => {
	const { input } = predefinedProps;

	if (input == null) {
		return { ...predefinedProps, input: { className: 'prompt' } };
	}
	if (_.isPlainObject(input)) {
		// @ts-ignore
		return { ...predefinedProps, input: { ...input, className: clsx(input.className, 'prompt') } };
	}

	return predefinedProps;
};

/** A search module allows a user to query for results from a selection of data */
export const Search = forwardRef(function Search(props: SearchProps, ref: ForwardedRef<HTMLDivElement>) {
	const {
		icon = 'search',
		input = 'text',
		minCharacters = 1,
		noResultsMessage = 'No results found.',
		showNoResults = true,
		...rest
	} = props;

	return (
		<SearchInner
			icon={icon}
			input={input}
			minCharacters={minCharacters}
			noResultsMessage={noResultsMessage}
			showNoResults={showNoResults}
			{...rest}
			innerRef={ref}
		/>
	);
});

type SearchInnerProps = SearchProps & { innerRef: ForwardedRef<HTMLDivElement> };

type SearchState = ModernAutoControlledComponentState<SearchInnerProps> & {
	focus: boolean;
	open: boolean;
	prevValue?: string;
	value: string;
	selectedIndex: number;
	searchClasses: string;
};

class SearchInner extends ModernAutoControlledComponent<SearchInnerProps, SearchState> {
	private isMouseDown = false;

	public constructor(props: SearchInnerProps) {
		super(props, ['open', 'value'], SearchInner.getAutoControlledStateFromProps);
	}

	public static getAutoControlledStateFromProps(
		this: void,
		props: SearchInnerProps,
		state: SearchState
	): Partial<SearchState> {
		// We need to store a `prevValue` to compare as in `getDerivedStateFromProps` we don't have
		// prevState
		if (typeof state.prevValue !== 'undefined' && _.isEqual(state.prevValue, state.value)) {
			return { prevValue: state.value };
		}

		const selectedIndex = props.selectFirstResult ? 0 : -1;
		return { prevValue: state.value, selectedIndex };
	}

	public override shouldComponentUpdate(nextProps: SearchProps, nextState: SearchState) {
		return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
	}

	public override componentDidUpdate(prevProps: SearchProps, prevState: SearchState) {
		// focused / blurred
		if (!prevState.focus && this.state.focus) {
			if (!this.isMouseDown) {
				this.tryOpen();
			}
			if (this.state.open) {
				window.addEventListener('keydown', this.moveSelectionOnKeyDown);
				window.addEventListener('keydown', this.selectItemOnEnter);
			}
		} else if (prevState.focus && !this.state.focus) {
			if (!this.isMouseDown) {
				this.close();
			}
			window.removeEventListener('keydown', this.moveSelectionOnKeyDown);
			window.removeEventListener('keydown', this.selectItemOnEnter);
		}

		// opened / closed
		if (!prevState.open && this.state.open) {
			this.open();
			window.addEventListener('click', this.closeOnDocumentClick, { capture: true });
			window.addEventListener('keydown', this.closeOnEscape, { capture: true });
			window.addEventListener('keydown', this.moveSelectionOnKeyDown, { capture: true });
			window.addEventListener('keydown', this.selectItemOnEnter, { capture: true });
		} else if (prevState.open && !this.state.open) {
			this.close();
			window.removeEventListener('click', this.closeOnDocumentClick, { capture: true });
			window.removeEventListener('keydown', this.closeOnEscape, { capture: true });
			window.removeEventListener('keydown', this.moveSelectionOnKeyDown, { capture: true });
			window.removeEventListener('keydown', this.selectItemOnEnter, { capture: true });
		}
	}

	public override componentWillUnmount() {
		window.removeEventListener('click', this.closeOnDocumentClick, { capture: true });
		window.removeEventListener('keydown', this.closeOnEscape, { capture: true });
		window.removeEventListener('keydown', this.moveSelectionOnKeyDown, { capture: true });
		window.removeEventListener('keydown', this.selectItemOnEnter, { capture: true });
	}

	// ----------------------------------------
	// Document Event Handlers
	// ----------------------------------------

	private readonly handleResultSelect = (e: Event, result: SearchResultProps) => {
		this.props.onResultSelect?.(e, { ...this.props, result });
	};

	private readonly handleSelectionChange = (e: Event) => {
		const result = this.getSelectedResult();
		this.props.onSelectionChange?.(e, { ...this.props, result });
	};

	private readonly closeOnEscape = (e: Event) => {
		if ((e as KeyboardEvent).key !== 'Escape') {
			return;
		}
		e.preventDefault();
		this.close();
	};

	private readonly moveSelectionOnKeyDown: EventListener = e => {
		switch ((e as KeyboardEvent).key) {
			case 'ArrowDown':
				e.preventDefault();
				this.moveSelectionBy(e, 1);
				break;
			case 'ArrowUp':
				e.preventDefault();
				this.moveSelectionBy(e, -1);
				break;
			default:
				break;
		}
	};

	private readonly selectItemOnEnter: EventListener = e => {
		if ((e as KeyboardEvent).key !== 'Enter') {
			return;
		}

		const result = this.getSelectedResult();

		// prevent selecting null if there was no selected item value
		if (!result) {
			return;
		}

		e.preventDefault();

		// notify the onResultSelect prop that the user is trying to change value
		this.setValue(result.title);
		this.handleResultSelect(e, result);
		this.close();
	};

	private readonly closeOnDocumentClick = () => {
		this.close();
	};

	// ----------------------------------------
	// Component Event Handlers
	// ----------------------------------------

	private readonly handleMouseDown: EventListener = e => {
		this.isMouseDown = true;
		this.props.onMouseDown?.(e, this.props);
		window.addEventListener('mouseup', this.handleDocumentMouseUp);
	};

	private readonly handleDocumentMouseUp = () => {
		this.isMouseDown = false;
		window.removeEventListener('mouseup', this.handleDocumentMouseUp);
	};

	private readonly handleInputClick = (e: MouseEvent<HTMLInputElement>) => {
		// prevent closeOnDocumentClick()
		e.nativeEvent.stopImmediatePropagation();

		this.tryOpen();
	};

	private readonly handleItemClick = (e: MouseEvent<HTMLDivElement>, { id }: SearchResultProps) => {
		const result = this.getSelectedResult(id)!;

		// prevent closeOnDocumentClick()
		e.nativeEvent.stopImmediatePropagation();

		// notify the onResultSelect prop that the user is trying to change value
		this.setValue(result.title);
		this.handleResultSelect(e.nativeEvent, result);
		this.close();
	};

	private readonly handleItemMouseDown = (e: MouseEvent<HTMLDivElement>) => {
		// Heads up! We should prevent default to prevent blur events.
		// https://github.com/Semantic-Org/Semantic-UI-React/issues/3298
		e.preventDefault();
	};

	private readonly handleFocus = (e: FocusEvent<HTMLInputElement>) => {
		this.props.onFocus?.(e, this.props);
		this.setState({ focus: true });
	};

	private readonly handleBlur = (e: FocusEvent<HTMLDivElement>) => {
		this.props.onBlur?.(e, this.props);
		this.setState({ focus: false });
	};

	private readonly handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		// prevent propagating to this.props.onChange()
		e.stopPropagation();
		const { minCharacters } = this.props;
		const { open } = this.state;
		const newQuery = e.target.value;

		this.props.onSearchChange?.(e, { ...this.props, value: newQuery });

		// open search dropdown on search query
		if (newQuery.length < Number(minCharacters)) {
			this.close();
		} else if (!open) {
			this.tryOpen(newQuery);
		}

		this.setValue(newQuery);
	};

	// ----------------------------------------
	// Getters
	// ----------------------------------------

	private readonly getFlattenedResults = (): SearchResultProps[] => {
		const { category, results } = this.props;

		return !category
			? (results as SearchResultProps[])
			: (results as SearchCategoryProps[]).reduce(
					(memo, categoryData) => memo.concat(categoryData.results!),
					[] as SearchResultProps[]
				);
	};

	private readonly getSelectedResult = (
		index: number | string = this.state.selectedIndex
	): SearchResultProps | undefined => {
		const results = this.getFlattenedResults();
		return _.get(results, index);
	};

	// ----------------------------------------
	// Setters
	// ----------------------------------------

	private readonly setValue = (value: string) => {
		const { selectFirstResult } = this.props;

		this.setState({ value, selectedIndex: selectFirstResult ? 0 : -1 });
	};

	private readonly moveSelectionBy = (e: Event, offset: number) => {
		const { selectedIndex } = this.state;

		const results = this.getFlattenedResults();
		const lastIndex = results.length - 1;

		// next is after last, wrap to beginning
		// next is before first, wrap to end
		let nextIndex = selectedIndex + offset;
		if (nextIndex > lastIndex) {
			nextIndex = 0;
		} else if (nextIndex < 0) {
			nextIndex = lastIndex;
		}

		this.setState({ selectedIndex: nextIndex });
		this.scrollSelectedItemIntoView();
		this.handleSelectionChange(e);
	};

	// ----------------------------------------
	// Behavior
	// ----------------------------------------

	private readonly scrollSelectedItemIntoView = () => {
		const menu = document.querySelector('.ui.search.active.visible .results.visible');
		if (!menu) {
			return;
		}
		const item = menu.querySelector('.result.active') as HTMLElement | undefined;
		if (!item) {
			return;
		}
		const isOutOfUpperView = item.offsetTop < menu.scrollTop;
		const isOutOfLowerView = item.offsetTop + item.clientHeight > menu.scrollTop + menu.clientHeight;

		if (isOutOfUpperView) {
			menu.scrollTop = item.offsetTop;
		} else if (isOutOfLowerView) {
			menu.scrollTop = item.offsetTop + item.clientHeight - menu.clientHeight;
		}
	};

	// Open if the current value is greater than the minCharacters prop
	private readonly tryOpen = (currentValue = this.state.value) => {
		const { minCharacters } = this.props;
		if (currentValue.length < Number(minCharacters)) {
			return;
		}

		this.open();
	};

	private readonly open = () => {
		this.setState({ open: true });
	};

	private readonly close = () => {
		this.setState({ open: false });
	};

	// ----------------------------------------
	// Render
	// ----------------------------------------

	private readonly renderSearchInput = (rest: Record<string, unknown>) => {
		const { icon, input, placeholder } = this.props;
		const { value } = this.state;

		return createInput(input, {
			autoGenerateKey: false,
			defaultProps: {
				...rest,
				autoComplete: 'off',
				icon,
				onChange: this.handleSearchChange,
				onClick: this.handleInputClick,
				tabIndex: 0,
				value,
				placeholder
			},
			// Nested shorthand props need special treatment to survive the shallow merge
			overrideProps: overrideSearchInputProps
		});
	};

	private readonly renderNoResults = () => {
		const { noResultsDescription, noResultsMessage } = this.props;

		return (
			<div className="message empty">
				<div className="header">{noResultsMessage}</div>
				{noResultsDescription ? <div className="description">{noResultsDescription}</div> : null}
			</div>
		);
	};

	/**
	 * Offset is needed for determining the active item for results within a category. Since the index is reset to 0 for
	 * each new category, an offset must be passed in.
	 */
	private readonly renderResult = (
		{ childKey, ...result }: SearchResultProps,
		index: number,
		_array: SearchResultProps[],
		offset = 0
	) => {
		const { resultRenderer } = this.props;
		const { selectedIndex } = this.state;
		const offsetIndex = index + offset;

		return (
			<SearchResult
				key={childKey ?? (result.id || result.title)}
				active={selectedIndex === offsetIndex}
				onClick={this.handleItemClick}
				onMouseDown={this.handleItemMouseDown}
				renderer={resultRenderer}
				{...result}
				id={offsetIndex} // Used to lookup the result on item click
			/>
		);
	};

	private readonly renderResults = () => {
		const { results } = this.props;

		return (results as SearchResultProps[]).map(this.renderResult);
	};

	private readonly renderCategories = () => {
		const { categoryLayoutRenderer, categoryRenderer, results: categories } = this.props;
		const { selectedIndex } = this.state;

		let count = 0;

		return (categories as SearchCategoryProps[]).map(({ childKey, ...category }) => {
			const categoryProps = {
				active: _.inRange(selectedIndex, count, count + category.results!.length),
				layoutRenderer: categoryLayoutRenderer,
				renderer: categoryRenderer,
				...category
			};
			const renderFn = _.partialRight(this.renderResult, count);

			count += category.results!.length;

			return (
				<SearchCategory key={childKey ?? category.name} {...categoryProps}>
					{category.results!.map(renderFn)}
				</SearchCategory>
			);
		});
	};

	private readonly renderMenuContent = () => {
		const { category, showNoResults, results } = this.props;

		if (results == null || results.length === 0) {
			return showNoResults ? this.renderNoResults() : null;
		}

		return category ? this.renderCategories() : this.renderResults();
	};

	private readonly renderResultsMenu = () => {
		const { open } = this.state;
		const resultsClasses = open ? 'visible' : '';
		const menuContent = this.renderMenuContent();

		if (!menuContent) {
			return;
		}

		return <SearchResults className={resultsClasses}>{menuContent}</SearchResults>;
	};

	public override render() {
		const { searchClasses, focus, open } = this.state;
		const { aligned, category, className, innerRef, fluid, loading, size } = this.props;

		// Classes
		const classes = clsx(
			'ui',
			open && 'active visible',
			size,
			searchClasses,
			keyOnly(category, 'category'),
			keyOnly(focus, 'focus'),
			keyOnly(fluid, 'fluid'),
			keyOnly(loading, 'loading'),
			valueAndKey(aligned, 'aligned'),
			'search',
			className
		);
		const unhandled = getUnhandledProps(handledProps, this.props);
		const ElementType = getComponentType(this.props);
		const [htmlInputProps, rest] = partitionHTMLProps(unhandled, {
			htmlProps: htmlInputAttrs
		});

		return (
			<ElementType
				{...rest}
				className={classes}
				onBlur={this.handleBlur}
				onFocus={this.handleFocus}
				onMouseDown={this.handleMouseDown}
				ref={innerRef}
			>
				{this.renderSearchInput(htmlInputProps)}
				{this.renderResultsMenu()}
			</ElementType>
		);
	}
}

const handledProps = [
	'aligned',
	'as',
	'category',
	'categoryLayoutRenderer',
	'categoryRenderer',
	'className',
	'defaultOpen',
	'defaultValue',
	'fluid',
	'icon',
	'input',
	'loading',
	'minCharacters',
	'noResultsDescription',
	'noResultsMessage',
	'onBlur',
	'onFocus',
	'onMouseDown',
	'onResultSelect',
	'onSearchChange',
	'onSelectionChange',
	'open',
	'placeholder',
	'resultRenderer',
	'results',
	'selectFirstResult',
	'showNoResults',
	'size',
	'value'
];
