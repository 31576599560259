import { QUERY, useQueryOperation } from 'api/Query';
import type { JSX } from 'react';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { DEFAULT_STALE_TIME } from 'ts/base/perspective/topbar/warnings/GlobalWarnings';

/** Warns the user when the default admin account and password is enabled. */
export function useDefaultAdminEnabledWarning(): JSX.Element | null {
	const { data: warnForDefaultAdminEnabled } = useQueryOperation(QUERY.showDefaultAdminWarning(), {
		staleTime: DEFAULT_STALE_TIME
	});
	if (!warnForDefaultAdminEnabled) {
		return null;
	}
	return (
		<GlobalWarning
			message="Default admin user enabled."
			tooltip={
				'You can either change the admin password or disable the user (under the Admin->Users tab) ' +
				'or disable this warning ' +
				'(using the system property -Dcom.teamscale.feature-toggle.no-warn-on-unsafe-admin-user=true)'
			}
			severity="warning"
		/>
	);
}
