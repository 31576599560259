import clsx from 'clsx';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { QUERY, useQueryOperation } from 'ts/api/Query';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { Links } from 'ts/commons/links/Links';
import { StringUtils } from 'ts/commons/StringUtils';
import { useTreeMapContextIfAvailable } from 'ts/commons/treemap/TreeMap.context';
import { Button } from 'ts/components/Button';
import { Icon } from 'ts/components/Icon';
import { Popup } from 'ts/components/Popup';
import type { ProjectPathParameterValue } from 'ts/perspectives/dashboard/widgets/parameters/ProjectPathParameter';

type WidgetTitleProps = {
	title: string | undefined;
	projectAndPath?: ProjectPathParameterValue;
	commit?: UnresolvedCommitDescriptor;
	warnings: string[];
};

/** Creates the title for a widget. */
export function WidgetTitle({ projectAndPath, title, commit, warnings }: WidgetTitleProps) {
	const treeMapContext = useTreeMapContextIfAvailable();
	return (
		<div className="widget-title">
			<Warnings warnings={warnings} />
			<strong>{title}</strong>
			{projectAndPath && !projectAndPath.hiddenInWidgetTitle ? (
				<>
					{' for '}
					<MetricsLink
						project={projectAndPath.project}
						uniformPath={treeMapContext?.drillDownPath.value ?? projectAndPath.path}
						commit={commit}
					/>
				</>
			) : null}
		</div>
	);
}

function Warnings({ warnings }: { warnings: string[] }) {
	if (ArrayUtils.isEmpty(warnings)) {
		return null;
	}
	return (
		<Popup
			trigger={
				<Button
					secondary
					circular
					compact
					size="mini"
					icon={<Icon name="warning sign" color="yellow" />}
					className="!mr-2"
					data-testid="widget-warning"
				/>
			}
		>
			<b>{StringUtils.pluralize(warnings.length, 'Warning')}</b>
			<ul className={clsx({ 'list-none !ml-0': warnings.length === 1 })}>
				{warnings.map((warning, index) => (
					<li key={index}>{warning}</li>
				))}
			</ul>
		</Popup>
	);
}

type MetricsLinkProps = {
	project: string;
	uniformPath: string;
	commit?: UnresolvedCommitDescriptor;
};

function MetricsLink({ project, uniformPath, commit }: MetricsLinkProps) {
	const text = `${project}${uniformPath ? `/${uniformPath}` : ''}`;

	const resourceTypeResult = useQueryOperation(
		QUERY.getResourceType(project, {
			t: commit,
			'uniform-path': uniformPath,
			'check-default-branch': true
		}),
		{ throwOnError: false }
	);
	return (
		<TeamscaleLink
			className="underline"
			to={Links.metrics(project, uniformPath, {
				commit,
				resourceType: resourceTypeResult.data
			})}
			title={text}
		>
			{text}
		</TeamscaleLink>
	);
}
