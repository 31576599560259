import { useProjectInfos } from 'ts/base/hooks/ProjectsInfosHook';

/**
 * Renders and returns a HTML-DIV template informing the user at a point that no data is available for a given widget.
 *
 * @param analysisInProgress True if analysis of projects is in progress. This will help to inform users to wait until
 *   analysis is over before viewing widget data.
 */
export function NoWidgetDataAvailable({ projectId }: { projectId: string | null }) {
	const projectInfos = useProjectInfos();
	const analysisInProgress =
		(projectId == null && projectInfos.projects.length === 0 && projectInfos.initialProjects.length > 0) ||
		(projectId != null && projectInfos.initialProjects.includes(projectId));

	return (
		<div id="no-data-available">
			<div>
				<strong style={{ color: '#c33434' }}>
					Data is unavailable. {analysisInProgress ? 'Analysis in progress...' : null}
				</strong>
			</div>
		</div>
	);
}
