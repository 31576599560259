import {
	type ComponentPropsWithoutRef,
	type ElementType,
	type ForwardedRef,
	forwardRef,
	type MouseEvent,
	type ReactNode
} from 'react';
import { createGridColumn } from 'ts/components/Grid/GridColumn';
import type { MenuItemProps } from 'ts/components/Menu';
import type { SemanticShorthandItem, SemanticWIDTHS } from '../Generic';
import { Grid, type GridProps } from '../Grid';
import { getComponentType, getUnhandledProps, useAutoControlledValue } from '../lib';
import { createMenu, type MenuProps } from '../Menu/Menu';
import { createTabPane, type TabPaneProps } from './TabPane';

/** Props for {@link Tab}. */
export type TabProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The initial activeIndex. */
	defaultActiveIndex?: number;

	/** Index of the currently active tab. */
	activeIndex?: number;

	/** Shorthand props for the Menu. */
	menu?: MenuProps;

	/** Align vertical menu */
	menuPosition?: 'left' | 'right';

	/** Shorthand props for the Grid. */
	grid?: GridProps & { paneWidth?: SemanticWIDTHS; tabWidth?: SemanticWIDTHS };

	/**
	 * Called on tab change.
	 *
	 * @param event - React's original SyntheticEvent.
	 * @param data - The proposed new Tab.Pane.
	 * @param data.activeIndex - The new proposed activeIndex.
	 * @param data.panes - Props of the new proposed active pane.
	 */
	onTabChange?: (event: MouseEvent<HTMLAnchorElement>, data: TabProps) => void;

	/**
	 * Array of objects describing each Menu.Item and Tab.Pane: { menuItem: 'Home', render: () =>
	 * <Tab.Pane>Welcome!</Tab.Pane>, } or { menuItem: 'Home', pane: 'Welcome', }
	 */
	panes?: Array<{
		pane?: SemanticShorthandItem<TabPaneProps>;
		menuItem?: SemanticShorthandItem<MenuItemProps>;
		render?: () => ReactNode;
	}>;

	/** A Tab can render only active pane. */
	renderActiveOnly?: boolean;
};

/**
 * A Tab is a hidden section of content activated by a Menu.
 *
 * @see Menu
 * @see Segment
 */
export const Tab = forwardRef(function Tab(props: TabProps, ref: ForwardedRef<HTMLDivElement>) {
	const {
		grid = { paneWidth: 12, tabWidth: 4 },
		menu = { attached: true, tabular: true },
		menuPosition,
		panes,
		renderActiveOnly = true
	} = props;

	const [activeIndex, setActiveIndex] = useAutoControlledValue({
		state: props.activeIndex,
		defaultState: props.defaultActiveIndex,
		initialState: 0
	});

	const handleItemClick = (e: MouseEvent<HTMLAnchorElement>, { index }: MenuItemProps) => {
		props.onTabChange?.(e, { ...props, activeIndex: index });
		setActiveIndex(index);
	};

	const renderItems = () => {
		if (renderActiveOnly) {
			return panes?.[activeIndex]?.render?.();
		}

		return panes!.map(({ pane }, index) =>
			createTabPane(pane, {
				overrideProps: {
					active: index === activeIndex
				}
			})
		);
	};

	const renderMenu = (): JSX.Element => {
		const menuCopy = { ...menu };
		if (menuCopy.tabular === true && menuPosition === 'right') {
			menuCopy.tabular = 'right';
		}

		return createMenu(menuCopy, {
			autoGenerateKey: false,
			overrideProps: {
				items: panes?.map(pane => pane.menuItem),
				onItemClick: handleItemClick,
				activeIndex
			}
		})!;
	};

	const renderVertical = (menuElement: JSX.Element) => {
		const { paneWidth, tabWidth, ...gridProps } = grid;

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		const position = menuPosition || (menuElement.props.tabular === 'right' && 'right') || 'left';

		return (
			<Grid {...gridProps}>
				{position === 'left' &&
					createGridColumn({ width: tabWidth, children: menuElement }, { autoGenerateKey: false })}
				{createGridColumn(
					{
						width: paneWidth,
						children: renderItems(),
						stretched: true
					},
					{ autoGenerateKey: false }
				)}
				{position === 'right' &&
					createGridColumn({ width: tabWidth, children: menuElement }, { autoGenerateKey: false })}
			</Grid>
		);
	};

	const menuElement = renderMenu();
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	if (menuElement.props.vertical) {
		return (
			<ElementType {...rest} ref={ref}>
				{renderVertical(menuElement)}
			</ElementType>
		);
	}

	return (
		<ElementType {...rest} ref={ref}>
			{menuElement.props.attached !== 'bottom' && menuElement}
			{renderItems()}
			{menuElement.props.attached === 'bottom' && menuElement}
		</ElementType>
	);
});
const handledProps = [
	'activeIndex',
	'as',
	'defaultActiveIndex',
	'grid',
	'menu',
	'menuPosition',
	'onTabChange',
	'panes',
	'renderActiveOnly'
];
