import { createContext, type ReactNode, useContext } from 'react';
import { SearchContextProvider } from 'ts/commons/search/Search.context';
import { type TreeMapDrillDownProps, useTreeMapDrillDownProps } from './features/TreeMapDrillDownModeButton';

type TreeMapContextValues = Pick<TreeMapContextProviderProps, 'drillDownModeSupported'> & TreeMapDrillDownProps;

const TreeMapContext = createContext<TreeMapContextValues | undefined>(undefined);

type TreeMapContextProviderProps = {
	children: ReactNode;

	/** Whether the drill-down mode is enabled by default. */
	defaultDrillDownEnabled?: boolean;

	/** Whether the drill-down mode is supported for the treemap. */
	drillDownModeSupported?: boolean;
};

/**
 * Context provider for a treemap. Each treemap must be wrapped in such a context provider. TreeMapWithButtons and
 * components using it already include the context provider. In cases where the treemap buttons should be rendered in
 * some other place as directly above the treemap composing the context, TreeMap and TreeMapButtons together allow
 * building arbitrary layouts.
 */
export function TreeMapContextProvider({
	drillDownModeSupported = false,
	defaultDrillDownEnabled = false,
	children
}: TreeMapContextProviderProps) {
	const drillDownProps = useTreeMapDrillDownProps(defaultDrillDownEnabled);
	return (
		<SearchContextProvider>
			<TreeMapContext.Provider
				value={{
					drillDownModeSupported,
					...drillDownProps
				}}
			>
				{children}
			</TreeMapContext.Provider>
		</SearchContextProvider>
	);
}

/**
 * Controlled context provider for a TreeMap, i.e., the values are stored externally, and not internally managed by the
 * context.
 *
 * When using this context provider, the caller must ensure that a {@code SearchContext} is also enabled.
 */
export function ControlledTreeMapContextProvider({
	children,
	...props
}: TreeMapContextValues & { children: ReactNode }) {
	return <TreeMapContext.Provider value={props}>{children}</TreeMapContext.Provider>;
}

/** Returns the treemap context. */
export function useTreeMapContext(): TreeMapContextValues {
	const context = useContext(TreeMapContext);
	if (context === undefined) {
		throw new Error('useTreeMapContext must be used within a TreeMapContextProvider');
	}
	return context;
}

/** Returns the treemap context if there is one present. */
export function useTreeMapContextIfAvailable(): TreeMapContextValues | undefined {
	return useContext(TreeMapContext);
}
