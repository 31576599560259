import { QUERY, useQueryOperation } from 'api/Query';
import type { JSX } from 'react';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { DEFAULT_STALE_TIME } from 'ts/base/perspective/topbar/warnings/GlobalWarnings';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { Links } from 'ts/commons/links/Links';

/** Informs the user that shadow mod is enabled. */
export function useShadowModeWarning(): JSX.Element | null {
	const { data: shadowMode } = useQueryOperation(QUERY.showShadowModeWarning(), {
		staleTime: DEFAULT_STALE_TIME,
		refetchOnReconnect: 'always'
	});

	if (!shadowMode) {
		return null;
	}
	return (
		<GlobalWarning
			message={
				<>
					Shadow Mode (<TeamscaleLink to={Links.showAdminSettings()}>change</TeamscaleLink>)
				</>
			}
			severity="warning"
			tooltip="Shadow mode disables all external notifications, including emails, pull request annotations, and SAP downloads."
		/>
	);
}
