import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import { UniformPath } from 'ts/commons/UniformPath';
import { ETestGapsPerspectiveView } from 'ts/perspectives/tests/ETestGapsPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { EType } from 'typedefs/EType';

/** Returns a URL for the TGA metrics table. */
export function testGapMetrics(
	project: string,
	uniformPath: string,
	{
		commit,
		partitions
	}: {
		commit?: UnresolvedCommitDescriptor | null;
		partitions?: string[] | null;
	}
) {
	let view: ViewDescriptor;
	if (new UniformPath(uniformPath).isOfType(EType.ARCHITECTURE)) {
		view = ETestGapsPerspectiveView.ARCHITECTURE_COMPONENTS;
	} else {
		view = ETestGapsPerspectiveView.TEST_GAPS;
	}
	return linkTo(ETeamscalePerspective.TEST_GAPS, view, project, uniformPath, { t: commit, partitions });
}

/** Returns a URL for the TGA issue overview. */
export function issueTgaOverview(project: string, queryName?: string) {
	return linkTo(ETeamscalePerspective.TEST_GAPS, ETestGapsPerspectiveView.ISSUE_TEST_GAP, project, {
		'issue-query': queryName
	});
}

/** Returns a URL for the TGA unlinked changes view. */
export function unlinkedChangesTga(project: string, baseline: TypedPointInTime, commit: UnresolvedCommitDescriptor) {
	return linkTo(ETeamscalePerspective.TEST_GAPS, ETestGapsPerspectiveView.UNLINKED_CHANGES_TEST_GAP, project, {
		baseline: TimeUtils.toUrlToken(baseline),
		t: commit
	});
}
