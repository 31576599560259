import { QUERY } from 'api/Query';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type { FileListEntry } from 'ts/commons/dialog/PathEntitySelectionModalContent';
import { PathEntitySelectionModalContent } from 'ts/commons/dialog/PathEntitySelectionModalContent';
import {
	buildAndSortEntries,
	getPathEntryForProjects,
	insertPathPrefix
} from 'ts/commons/dialog/PathEntitySelectionUtils';
import { openModal } from 'ts/commons/modal/ModalUtils';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { StringUtils } from 'ts/commons/StringUtils';
import { UniformPath } from 'ts/commons/UniformPath';
import { Button } from 'ts/components/Button';
import { FormInput } from 'ts/components/Form';
import { EResourceType, type EResourceTypeEntry } from 'typedefs/EResourceType';
import { EType } from 'typedefs/EType';

type ProjectAndPathSelectionInputFieldProps = {
	/** The name of the project name in the form values */
	projectNameField: string;
	/** The name of the uniform path in the form values */
	uniformPathField: string;
	/** Input field label */
	label: string;
};

/** Provides an input field to select project and path. It must be wrapped around a form provider. */
export function ProjectAndPathSelectionInputField({
	projectNameField,
	uniformPathField,
	label
}: ProjectAndPathSelectionInputFieldProps) {
	const { control, setValue } = useFormContext();
	const formValues = useWatch({ control });
	const [projectAndPathValue, setProjectAndPathValue] = useState(
		ProjectAndUniformPath.of(formValues[projectNameField], formValues[uniformPathField])
	);

	return (
		<FormInput
			data-testid="project-and-path-field"
			label={label}
			readOnly
			action={
				<Button
					type="button"
					data-testid="path-picker-button"
					icon="ellipsis horizontal"
					onClick={() =>
						showProjectAndPathSelectionModal({
							initialProject: formValues[projectNameField],
							initialPath: new UniformPath(formValues[uniformPathField]),
							onSave: (project: string, path: UniformPath) => {
								setValue(projectNameField, project);
								setValue(uniformPathField, path.getPath().toString());
								setProjectAndPathValue(ProjectAndUniformPath.of(project, path.getPath()));
							}
						})
					}
				/>
			}
			value={projectAndPathValue.toString()}
		/>
	);
}

type ProjectAndPathSelectionModalOptions = {
	onSave: (project: string, path: UniformPath, commit: UnresolvedCommitDescriptor) => void;
	initialProject?: string;
	initialPath?: UniformPath;
	initialCommit?: UnresolvedCommitDescriptor;
	forbiddenUniformPathTypes?: EType[];
	disableBranchAndTimeSelection?: boolean;
};

/** Renders the project and path selection modal. */
export function showProjectAndPathSelectionModal({
	onSave,
	initialProject = NavigationHash.getProject(),
	initialPath,
	initialCommit,
	disableBranchAndTimeSelection
}: ProjectAndPathSelectionModalOptions) {
	openModal({
		size: 1200,
		title: 'Select Project and Path',
		'data-testid': 'path-selection-modal',
		contentRenderer: close => (
			<PathEntitySelectionModalContent
				projectsSelectable
				initialProject={initialProject}
				initialPath={initialPath}
				initialCommit={initialCommit}
				onSave={(project: string | undefined, path: UniformPath, commit: UnresolvedCommitDescriptor) => {
					onSave(project!, path, commit);
				}}
				onClose={close}
				loadEntries={loadProjectAndPathData}
				disableCommitSelector={disableBranchAndTimeSelection}
			/>
		)
	});
}

async function loadProjectAndPathData(
	project: string,
	path: UniformPath,
	selectedCommit: UnresolvedCommitDescriptor,
	projectIds: string[]
): Promise<FileListEntry[]> {
	const showProjects = StringUtils.isEmptyOrWhitespace(project) && path.isEmpty();

	if (showProjects) {
		return getPathEntryForProjects(projectIds).filter(entry => entry.name.startsWith(project));
	}

	// Empty project should not be invalid, for example when selecting a project
	const invalidProject = !StringUtils.isEmptyOrWhitespace(project) && !projectIds.includes(project);

	// Projects need to be checked here again in case the current project is empty
	if (invalidProject) {
		throw new Error(`Invalid project ${project}!`);
	}

	const [, containers] = await Promise.all([
		loadResourceType(project, path, selectedCommit),
		loadContainers(project, path, selectedCommit)
	]);

	return containers;
}

/** Fetches the info for the file list entries. Returns null if the path is invalid */
export async function loadContainers(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor
): Promise<FileListEntry[]> {
	const [, childResourceTypes, displayMetrics] = await Promise.all([
		loadResourceType(project, path, commit),
		loadChildResourceTypes(project, path, commit),
		loadDisplayMetrics(project, path, commit)
	]);

	const entries = buildAndSortEntries(project, childResourceTypes);
	displayMetrics.forEach(path => insertPathPrefix(project, path, entries));

	return entries;
}

async function loadChildResourceTypes(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor
): Promise<Map<string, EResourceType>> {
	const resourceTypesWithChildrenQuery = await loadResourceTypesOfChildren(project, path, commit);

	const mapEntries = (obj: Record<string, EResourceTypeEntry>): Array<[string, EResourceType]> => {
		return Object.keys(obj).map(k => [k, EResourceType[obj[k]!]]);
	};
	return new Map(mapEntries(resourceTypesWithChildrenQuery));
}

/**
 * Fetches the resource type for the given uniform path.
 *
 * The given path can be invalid, then the resource type just will be UNKNOWN, however the project must exist, otherwise
 * this causes a 404 error.
 */
async function loadResourceType(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor
): Promise<EResourceTypeEntry> {
	if (project === '') {
		return EResourceType.CONTAINER.name;
	}
	const resourceType = await QUERY.getResourceType(project, {
		t: commit,
		'uniform-path': path.getPath(),
		'check-default-branch': true
	}).fetch();
	if (resourceType === EResourceType.UNKNOWN.name) {
		throw new Error(`Invalid path ${path.getPath()}!`);
	}
	return resourceType;
}

/**
 * Fetches the resource types of the children for the given uniform path.
 *
 * The given path can be invalid, then the resource type for the children will just be empty, however the project must
 * exist, otherwise this causes a 404 error.
 *
 * This is an extra method, even though the difference to {@see useResourceType} is only a single boolean, because the
 * return type of the used teamscale client method returns a completely different type.
 */
async function loadResourceTypesOfChildren(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor
): Promise<Record<string, EResourceTypeEntry>> {
	if (project === '') {
		return {};
	}
	return QUERY.getChildrenResourceTypes(project, {
		t: commit,
		'uniform-path': path.getPath()
	})
		.fetch()
		.catch(() => ({}));
}

async function loadDisplayMetrics(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor
): Promise<string[]> {
	if (!path.isProjectRoot() || project === '') {
		return [];
	}
	return (
		await Promise.all(
			[
				EType.NON_CODE.prefix,
				EType.ISSUE_QUERY.prefix,
				EType.TEST_IMPLEMENTATION.prefix,
				EType.TEST_EXECUTION.prefix,
				EType.TEST_QUERY.prefix,
				EType.SPEC_ITEM_QUERY.prefix
			].map(async constant => {
				if (
					(await loadResourceType(project, new UniformPath(constant), commit).catch(
						() => EResourceType.UNKNOWN.name
					)) === EResourceType.CONTAINER.name
				) {
					return [constant];
				}
				return [];
			})
		)
	).flat();
}
