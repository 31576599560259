/**
 * This file has been generated. Do not edit! 
 */

import type { VertexAiEngineProviderOption } from 'typedefs/VertexAiEngineProviderOption';
import type { UsageDataReportingOption } from 'typedefs/UsageDataReportingOption';
import type { TfsAuthorizationServerDescription } from 'typedefs/TfsAuthorizationServerDescription';
import type { SonarLintIndependentAnalysisCredentialsNameOption } from 'typedefs/SonarLintIndependentAnalysisCredentialsNameOption';
import type { SmtpServerOptions } from 'typedefs/SmtpServerOptions';
import type { ShadowModeOption } from 'typedefs/ShadowModeOption';
import type { SamlAuthenticationOption } from 'typedefs/SamlAuthenticationOption';
import type { RollbackLimitOption } from 'typedefs/RollbackLimitOption';
import type { RepositoryCloneOption } from 'typedefs/RepositoryCloneOption';
import type { PrometheusServiceOption } from 'typedefs/PrometheusServiceOption';
import type { PrometheusPushMetricsOption } from 'typedefs/PrometheusPushMetricsOption';
import type { PrometheusMetricsOption } from 'typedefs/PrometheusMetricsOption';
import type { PreCommitLimitsOption } from 'typedefs/PreCommitLimitsOption';
import type { OpenIdAuthenticationOption } from 'typedefs/OpenIdAuthenticationOption';
import type { OpenAiEngineProviderOption } from 'typedefs/OpenAiEngineProviderOption';
import type { OllamaEngineProviderOption } from 'typedefs/OllamaEngineProviderOption';
import type { MonitoringReportingOption } from 'typedefs/MonitoringReportingOption';
import type { MailNotificationOption } from 'typedefs/MailNotificationOption';
import type { LateExternalUploadScheduleOption } from 'typedefs/LateExternalUploadScheduleOption';
import type { LDAPServerDescription } from 'typedefs/LDAPServerDescription';
import type { InstanceLabelOption } from 'typedefs/InstanceLabelOption';
import type { InstanceIdOption } from 'typedefs/InstanceIdOption';
import type { InstanceComparisonTestGapOption } from 'typedefs/InstanceComparisonTestGapOption';
import type { InstanceComparisonMetricOption } from 'typedefs/InstanceComparisonMetricOption';
import type { InstanceComparisonMaintenanceLogOption } from 'typedefs/InstanceComparisonMaintenanceLogOption';
import type { InstanceComparisonLogErrorOption } from 'typedefs/InstanceComparisonLogErrorOption';
import type { InstanceComparisonFindingOption } from 'typedefs/InstanceComparisonFindingOption';
import type { GloballyEnforceDefaultStorageOption } from 'typedefs/GloballyEnforceDefaultStorageOption';
import type { GlobalSapSystemConnectionOption } from 'typedefs/GlobalSapSystemConnectionOption';
import type { GitPrivateKeyOption } from 'typedefs/GitPrivateKeyOption';
import type { GitHubOrganizationDescription } from 'typedefs/GitHubOrganizationDescription';
import type { GitHubCheckRunCleanupOption } from 'typedefs/GitHubCheckRunCleanupOption';
import type { GitHubApplicationDescription } from 'typedefs/GitHubApplicationDescription';
import type { GitAutoGCOption } from 'typedefs/GitAutoGCOption';
import type { GeminiAiEngineProviderOption } from 'typedefs/GeminiAiEngineProviderOption';
import type { FileSystemAccessOption } from 'typedefs/FileSystemAccessOption';
import type { ExternalUploadSessionRetentionOption } from 'typedefs/ExternalUploadSessionRetentionOption';
import type { ExternalUploadReportArchiveOption } from 'typedefs/ExternalUploadReportArchiveOption';
import type { ExternalStorageBackendOption } from 'typedefs/ExternalStorageBackendOption';
import type { DefaultBackupSettingsAcknowledgedOption } from 'typedefs/DefaultBackupSettingsAcknowledgedOption';
import type { DataPrivacyOption } from 'typedefs/DataPrivacyOption';
import type { CustomMailOption } from 'typedefs/CustomMailOption';
import type { CrowdServerDescription } from 'typedefs/CrowdServerDescription';
import type { ClangTidyExecutionServerCredentialsNameOption } from 'typedefs/ClangTidyExecutionServerCredentialsNameOption';
import type { BlacklistingOption } from 'typedefs/BlacklistingOption';
import type { BearerTokenAuthenticationOption } from 'typedefs/BearerTokenAuthenticationOption';
import type { BaseUrlOption } from 'typedefs/BaseUrlOption';
import type { AwsCredentialProcessVariableDefinition } from 'typedefs/AwsCredentialProcessVariableDefinition';
import type { AutomaticSsoLoginRedirectOption } from 'typedefs/AutomaticSsoLoginRedirectOption';
import type { AutoStorageSnapshotOption } from 'typedefs/AutoStorageSnapshotOption';
import type { AutoBackupOption } from 'typedefs/AutoBackupOption';
import type { AppleIdmsAppIdentificationOption } from 'typedefs/AppleIdmsAppIdentificationOption';
import type { AppleIdmsA3AuthOption } from 'typedefs/AppleIdmsA3AuthOption';
import type { AdminContactUrlOption } from 'typedefs/AdminContactUrlOption';
import type { AbapSystemDescription } from 'typedefs/AbapSystemDescription';

/** All known server options. */
export type ServerOptions = {
	'sap.abap.system': AbapSystemDescription;
	'admin.contact.url': AdminContactUrlOption;
	'apple.idms.a3.auth': AppleIdmsA3AuthOption;
	'apple.idms.application.identification': AppleIdmsAppIdentificationOption;
	'schedule.backup': AutoBackupOption;
	'schedule.snapshot-backup': AutoStorageSnapshotOption;
	'ts.sso-login-redirect': AutomaticSsoLoginRedirectOption;
	'aws.credentials.process.variable': AwsCredentialProcessVariableDefinition;
	'baseurl': BaseUrlOption;
	'auth.bearer_token': BearerTokenAuthenticationOption;
	'ts.blacklisting-option': BlacklistingOption;
	'clang-tidy-execution-server-account-name': ClangTidyExecutionServerCredentialsNameOption;
	'auth.crowd.server': CrowdServerDescription;
	'custom.mail.option': CustomMailOption;
	'ts.data-privacy': DataPrivacyOption;
	'acknowledge-backup-settings': DefaultBackupSettingsAcknowledgedOption;
	'default-external-storage-backend': ExternalStorageBackendOption;
	'external.report.archive': ExternalUploadReportArchiveOption;
	'external.session.retention': ExternalUploadSessionRetentionOption;
	'file-system-access': FileSystemAccessOption;
	'ai-engine.geminiai': GeminiAiEngineProviderOption;
	'repository.git.gc': GitAutoGCOption;
	'auth.github.application': GitHubApplicationDescription;
	'repository.github.check-run-cleanup': GitHubCheckRunCleanupOption;
	'auth.github.server': GitHubOrganizationDescription;
	'git.privatekey': GitPrivateKeyOption;
	'sap.abap.global': GlobalSapSystemConnectionOption;
	'enforce-global-default-storage': GloballyEnforceDefaultStorageOption;
	'instance-comparison-findings': InstanceComparisonFindingOption;
	'instance-comparison-log-error': InstanceComparisonLogErrorOption;
	'instance-comparison-maintenance-log': InstanceComparisonMaintenanceLogOption;
	'instance-comparison-metrics': InstanceComparisonMetricOption;
	'instance-comparison-test-gaps': InstanceComparisonTestGapOption;
	'instance-id': InstanceIdOption;
	'instance-label': InstanceLabelOption;
	'auth.ldap.server': LDAPServerDescription;
	'late.upload.schedule': LateExternalUploadScheduleOption;
	'mail.notification.option': MailNotificationOption;
	'monitoring-reporting': MonitoringReportingOption;
	'ai-engine.ollama': OllamaEngineProviderOption;
	'ai-engine.openai': OpenAiEngineProviderOption;
	'auth.openid': OpenIdAuthenticationOption;
	'precommit.limit.options': PreCommitLimitsOption;
	'prometheus-metrics': PrometheusMetricsOption;
	'prometheus-push-metrics': PrometheusPushMetricsOption;
	'prometheus-service': PrometheusServiceOption;
	'git.clone.directory': RepositoryCloneOption;
	'rollback.age-limit': RollbackLimitOption;
	'auth.saml': SamlAuthenticationOption;
	'ts.shadow-mode': ShadowModeOption;
	'mail.options': SmtpServerOptions;
	'sonar-lint-independent-analysis-credentials': SonarLintIndependentAnalysisCredentialsNameOption;
	'auth.tfs.server': TfsAuthorizationServerDescription;
	'usage-data-reporting': UsageDataReportingOption;
	'ai-engine.vertexai': VertexAiEngineProviderOption;
};

/** All known server option names. */
export enum EServerOptions {
	ABAP_SYSTEM_DESCRIPTION = 'sap.abap.system',
	ADMIN_CONTACT_URL = 'admin.contact.url',
	APPLE_IDMSA3_AUTH = 'apple.idms.a3.auth',
	APPLE_IDMS_APP_IDENTIFICATION = 'apple.idms.application.identification',
	AUTO_BACKUP = 'schedule.backup',
	AUTO_STORAGE_SNAPSHOT = 'schedule.snapshot-backup',
	AUTOMATIC_SSO_LOGIN_REDIRECT = 'ts.sso-login-redirect',
	AWS_CREDENTIAL_PROCESS_VARIABLE_DEFINITION = 'aws.credentials.process.variable',
	BASE_URL = 'baseurl',
	BEARER_TOKEN_AUTHENTICATION = 'auth.bearer_token',
	BLACKLISTING = 'ts.blacklisting-option',
	CLANG_TIDY_EXECUTION_SERVER_CREDENTIALS_NAME = 'clang-tidy-execution-server-account-name',
	CROWD_SERVER_DESCRIPTION = 'auth.crowd.server',
	CUSTOM_MAIL = 'custom.mail.option',
	DATA_PRIVACY = 'ts.data-privacy',
	DEFAULT_BACKUP_SETTINGS_ACKNOWLEDGED = 'acknowledge-backup-settings',
	EXTERNAL_STORAGE_BACKEND = 'default-external-storage-backend',
	EXTERNAL_UPLOAD_REPORT_ARCHIVE = 'external.report.archive',
	EXTERNAL_UPLOAD_SESSION_RETENTION = 'external.session.retention',
	FILE_SYSTEM_ACCESS = 'file-system-access',
	GEMINI_AI_ENGINE_PROVIDER = 'ai-engine.geminiai',
	GIT_AUTOGC = 'repository.git.gc',
	GIT_HUB_APPLICATION_DESCRIPTION = 'auth.github.application',
	GIT_HUB_CHECK_RUN_CLEANUP = 'repository.github.check-run-cleanup',
	GIT_HUB_ORGANIZATION_DESCRIPTION = 'auth.github.server',
	GIT_PRIVATE_KEY = 'git.privatekey',
	GLOBAL_SAP_SYSTEM_CONNECTION = 'sap.abap.global',
	GLOBALLY_ENFORCE_DEFAULT_STORAGE = 'enforce-global-default-storage',
	INSTANCE_COMPARISON_FINDING = 'instance-comparison-findings',
	INSTANCE_COMPARISON_LOG_ERROR = 'instance-comparison-log-error',
	INSTANCE_COMPARISON_MAINTENANCE_LOG = 'instance-comparison-maintenance-log',
	INSTANCE_COMPARISON_METRIC = 'instance-comparison-metrics',
	INSTANCE_COMPARISON_TEST_GAP = 'instance-comparison-test-gaps',
	INSTANCE_ID = 'instance-id',
	INSTANCE_LABEL = 'instance-label',
	LDAP_SERVER_DESCRIPTION = 'auth.ldap.server',
	LATE_EXTERNAL_UPLOAD_SCHEDULE = 'late.upload.schedule',
	MAIL_NOTIFICATION = 'mail.notification.option',
	MONITORING_REPORTING = 'monitoring-reporting',
	OLLAMA_ENGINE_PROVIDER = 'ai-engine.ollama',
	OPEN_AI_ENGINE_PROVIDER = 'ai-engine.openai',
	OPEN_ID_AUTHENTICATION = 'auth.openid',
	PRE_COMMIT_LIMITS = 'precommit.limit.options',
	PROMETHEUS_METRICS = 'prometheus-metrics',
	PROMETHEUS_PUSH_METRICS = 'prometheus-push-metrics',
	PROMETHEUS_SERVICE = 'prometheus-service',
	REPOSITORY_CLONE = 'git.clone.directory',
	ROLLBACK_LIMIT = 'rollback.age-limit',
	SAML_AUTHENTICATION = 'auth.saml',
	SHADOW_MODE = 'ts.shadow-mode',
	SMTP_SERVER_OPTIONS = 'mail.options',
	SONAR_LINT_INDEPENDENT_ANALYSIS_CREDENTIALS_NAME = 'sonar-lint-independent-analysis-credentials',
	TFS_AUTHORIZATION_SERVER_DESCRIPTION = 'auth.tfs.server',
	USAGE_DATA_REPORTING = 'usage-data-reporting',
	VERTEX_AI_ENGINE_PROVIDER = 'ai-engine.vertexai'
}
