import { type StateObject, useStateObject } from 'ts/base/hooks/UseStateSupport';
import { BetaLabel } from 'ts/commons/BetaLabel';
import { useTreeMapContext } from 'ts/commons/treemap/TreeMap.context';
import { Button } from 'ts/components/Button';
import { Popup } from 'ts/components/Popup';

/** Properties for {@link TreeMapDrillDownModeButton}. */
export type TreeMapDrillDownProps = {
	drillDownEnabled: StateObject<boolean>;
	drillDownPath: {
		value: string | undefined;
		setValue: (value: string) => void;
	};
};

/** Prepares and returns all props needed for the TreeMapDrillDownModeButton. */
export function useTreeMapDrillDownProps(defaultDrillDownEnabled: boolean): TreeMapDrillDownProps {
	const drillDownEnabled = useStateObject(defaultDrillDownEnabled);
	const drillDownPath = useStateObject<string>();
	return {
		drillDownEnabled,
		drillDownPath
	};
}

/**
 * Button that can be used to enable or disable drill-down mode. Should be used together with the property
 * "drillDownModeSupported".
 */
export function TreeMapDrillDownModeButton() {
	const { drillDownEnabled } = useTreeMapContext();
	return (
		<Popup
			wide
			trigger={
				<Button
					compact
					type="button"
					active={drillDownEnabled.value}
					title={(drillDownEnabled.value ? 'Disable' : 'Enable') + ' drill-down mode'}
					onClick={() => drillDownEnabled.setValue(!drillDownEnabled.value)}
					icon="expand"
					data-testid="set-treemap-drilldown-mode"
				/>
			}
			position="bottom right"
		>
			<p>
				<strong>Drill-down Mode</strong>
				<BetaLabel />
			</p>
			<p>
				Toggles the drill-down mode of this treemap. This allows you to identify and zoom into specific areas of
				interest as quickly as possible.
			</p>
			<p>
				Clicking on a label zooms into the corresponding area. To zoom out, use the breadcrumbs above the
				treemap. Hovering over a label reveals details about the aggregated value and path.
			</p>
			<p>
				Drill-down labels are only displayed if they fit into their corresponding box, but drilling down is
				enabled regardless.
			</p>
		</Popup>
	);
}
