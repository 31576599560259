import { createStore, useStore } from 'zustand';

type PerspectiveProgressState = {
	inProgress: number;
	startProgress: () => void;
	stopProgress: () => void;
};

/** Stores how many loading processes are currently in progress. */
const perspectiveProgressStore = createStore<PerspectiveProgressState>(set => ({
	inProgress: 0,
	startProgress() {
		set(oldState => ({
			inProgress: oldState.inProgress + 1
		}));
	},
	stopProgress() {
		set(oldState => ({
			inProgress: Math.max(oldState.inProgress - 1, 0)
		}));
	}
}));

/** Returns whether the perspective loading indicator should be shown. */
export function usePerspectiveProgressLoading() {
	return useStore(perspectiveProgressStore, state => state.inProgress > 0);
}

/**
 * A progress indicator to show for long-running actions. This class is a singleton, since it does not make sense to
 * show more than one progress bar at a time.
 */
export class PerspectiveProgress {
	/** Shows the progress bar underneath the navbar. If another progress bar is already shown, does nothing. */
	public static start(): void {
		perspectiveProgressStore.getState().startProgress();
	}

	/** Hides the progress bar. */
	public static stop(): void {
		perspectiveProgressStore.getState().stopProgress();
	}
}
