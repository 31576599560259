import { createContext, type ReactNode, useContext } from 'react';
import { Button, ButtonGroup } from 'ts/components/Button';

/** The values shared via the WidgetMenuContext. */
export type WidgetMenuContextValues = {
	onEdit: () => void;
	onDuplicate: () => void;
	onDelete: () => void;
	mayEditWidgets: boolean;
};

/** The context provided to the widget buttons. */
export const WidgetMenuContext = createContext<WidgetMenuContextValues | null>(null);

/** Renders the small menu in the title of containers and widget frames. */
export function WidgetEditMenu() {
	const { onEdit, onDuplicate, onDelete } = useContext(WidgetMenuContext)!;
	return (
		<ButtonGroup icon size="mini" className="edit-buttons">
			<Button
				compact
				icon="cog"
				data-testid="widget-edit-button"
				className="dashboard-button widget-button"
				title="Edit properties"
				onClick={onEdit}
			/>
			<Button
				compact
				icon="clone"
				data-testid="widget-duplicate-button"
				className="widget-button"
				title="Duplicate"
				onClick={onDuplicate}
			/>
			<Button
				compact
				icon="red trash"
				data-testid="widget-delete-button"
				className="widget-button"
				title="Delete"
				onClick={onDelete}
			/>
		</ButtonGroup>
	);
}

/** Renders the small menu in the title of containers and widget frames. */
export function WidgetQuickEditMenu({ children }: { children?: ReactNode }) {
	const { onEdit, mayEditWidgets } = useContext(WidgetMenuContext)!;
	return (
		<ButtonGroup icon size="mini" className="edit-buttons">
			{mayEditWidgets ? (
				<Button
					compact
					icon="cog"
					data-testid="widget-edit-button"
					className="dashboard-button widget-button"
					title="Edit properties"
					onClick={onEdit}
				/>
			) : null}
			{children}
		</ButtonGroup>
	);
}
