import { createContext, type ReactNode, useContext } from 'react';
import { type StateObject, useStateObject } from 'ts/base/hooks/UseStateSupport';

/** Context values of the search context */
type SearchContextValues = {
	/** The currently searched term. Maybe empty. */
	searchTerm: StateObject<string>;
	/** Whether the search is using regex functionality. */
	regexUsed: StateObject<boolean>;
	/** Whether the search bar is currently being shown. */
	showSearch: StateObject<boolean>;
};

const SearchContext = createContext<SearchContextValues | undefined>(undefined);

type SearchContextProviderProps = {
	children: ReactNode;
};

/** Context Provider for the search context used, e.g., in treemaps. */
export function SearchContextProvider({ children }: SearchContextProviderProps) {
	const searchTerm = useStateObject<string>('');
	const regexUsed = useStateObject<boolean>(false);
	const showSearch = useStateObject<boolean>(false);
	return (
		<SearchContext.Provider
			value={{
				searchTerm,
				regexUsed,
				showSearch
			}}
		>
			{children}
		</SearchContext.Provider>
	);
}

type ControlledSearchContextProviderProps = SearchContextValues & SearchContextProviderProps;

/**
 * Controlled context provider for the search context, i.e., the values are stored externally, and not internally
 * managed by the context.
 */
export function ControlledSearchContextProvider({ children, ...props }: ControlledSearchContextProviderProps) {
	return <SearchContext.Provider value={props}>{children}</SearchContext.Provider>;
}

/** Returns the search context */
export function useSearchContext(): SearchContextValues {
	const context = useContext(SearchContext);
	if (context === undefined) {
		throw new Error('SearchContext must be used within a SearchContextProvider');
	}
	return context;
}
