import { type Dispatch, type SetStateAction, useState } from 'react';

/** Array representation as returned by {@code useState} */
export type StateArray<T> = [state: T, setState: Dispatch<SetStateAction<T>>];

/**
 * Object representation of the array returned by {@code useState}. Can be used when a {@code useState} result is not
 * immediately deconstructed, but stored in a variable.
 */
export type StateObject<T> = { value: T; setValue: Dispatch<SetStateAction<T>> };

/** Converts a {@link StateArray} to a {@link StateObject} */
export function convertStateArray<T>(val: StateArray<T>): StateObject<T> {
	return { value: val[0], setValue: val[1] };
}

/** Shorthand for {@code convertStateArray(useState())} */
export function useStateObject<T = undefined>(): StateObject<T | undefined>;

/** Shorthand for {@code convertStateArray(useState(initialState))} */
export function useStateObject<T>(initialState: T | (() => T)): StateObject<T>;

/** Shorthand for {@code convertStateArray(useState(initialState))} */
export function useStateObject<T>(initialState?: T | (() => T)): StateObject<T | undefined> {
	return convertStateArray(useState(initialState));
}
