import { QUERY } from 'api/Query';
import { QUERY_CLIENT } from 'api/QueryClient';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { LoaderFunctionArgs } from 'react-router-dom';
import { defer, redirect as routerRedirect, replace } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import { getDefaultBranchForProjects } from 'ts/base/components/branch-chooser/UseBranchInfos';
import { createViewComponent } from 'ts/base/CreateViewComponent';
import { ProjectResolver } from 'ts/base/ProjectResolver';
import { BASE_NAME } from 'ts/base/routing/Router';
import { EXTENDED_PERSPECTIVE_CONTEXT_QUERY } from 'ts/base/services/PerspectiveContext';
import type { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { Assertions } from 'ts/commons/Assertions';
import { linkTo } from 'ts/commons/links/LinkTo';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { StringUtils } from 'ts/commons/StringUtils';
import { TimetravelUtils } from 'ts/commons/TimetravelUtils';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import type { BranchesInfo } from 'typedefs/BranchesInfo';
import { EUserOptions } from 'typedefs/UserOptions';
import { ProjectProvider } from './context/ProjectInfoContext';
import { TeamscaleViewContext } from './TeamscaleViewContext';

let initialLoad = true;

/** Holds all the generic data loaded when navigating to a view. */
export type TeamscaleViewLoaderData = {
	viewDescriptor: ViewDescriptor;
	loadingTimestamp: number;
	requestedProjectIds: string[];
	existingProjectIds: string[];
	view: Promise<JSX.Element>;
	defaultBranch: string | null;
};

function redirect(url: string) {
	const absoluteUrl = StringUtils.ensureStartsWith(url, '/');
	if (initialLoad) {
		initialLoad = false;
		return replace(absoluteUrl);
	}
	return routerRedirect(absoluteUrl);
}

/**
 * Loader function that is executed when a view is opened/being navigated to.
 *
 * It loads the perspective context, determines the `ViewDescriptor` that corresponds to the URL, makes sure a valid
 * view name, project and commit is set in the URL and starts loading the actual view component defined in the
 * `ViewDescriptor`.
 */
export async function teamscaleViewRootLoader(
	{ request }: LoaderFunctionArgs,
	perspectiveDescriptor: PerspectiveViewDescriptorBase
): Promise<object> {
	const url = new URL(request.url);
	const hash = NavigationHash.parse(StringUtils.stripPrefix(url.pathname, BASE_NAME) + url.search);
	const context = await QUERY_CLIENT.fetchQuery(EXTENDED_PERSPECTIVE_CONTEXT_QUERY);
	if (hash.getViewName() === '') {
		return redirect(linkToFirstAccessibleView(perspectiveDescriptor, context, hash));
	}
	const viewDescriptor = determineViewDescriptor(perspectiveDescriptor, context, hash);
	const projectId = perspectiveDescriptor.getProject(context, hash, viewDescriptor);

	if (projectId !== null) {
		ProjectResolver.setCurrentProject(projectId);
	}

	const requestedProjectIds = await loadProjects(projectId, hash, viewDescriptor, context, request.signal);
	const existingProjectIds = requestedProjectIds.filter(project => context.projectExists(project));

	const effectiveViewDescriptor = getEffectiveViewDescriptor(viewDescriptor, context, hash, requestedProjectIds);
	const { defaultBranch, shouldRedirect } = await getRedirectLocation(
		viewDescriptor,
		hash,
		existingProjectIds,
		projectId
	);
	if (shouldRedirect) {
		return redirect(hash.toString());
	}
	if (effectiveViewDescriptor.requiresProject && existingProjectIds.length > 0) {
		Assertions.assertString(defaultBranch, 'Default branch is not set!');
	}

	const viewProps = { projectIds: existingProjectIds, defaultBranchName: defaultBranch };
	const view = createViewComponent(context, hash, effectiveViewDescriptor, viewProps).then(viewComponent => (
		// Ensure that the view is always re-created from scratch
		<Fragment key={Date.now()}>
			<TeamscaleViewContext.Provider
				value={{
					projectIds: existingProjectIds,
					defaultBranchName: defaultBranch,
					viewDescriptor: effectiveViewDescriptor
				}}
			>
				<ProjectProvider projectId={hash.getProject()}>{viewComponent}</ProjectProvider>
			</TeamscaleViewContext.Provider>
		</Fragment>
	));

	const data: TeamscaleViewLoaderData = {
		viewDescriptor: effectiveViewDescriptor,
		loadingTimestamp: Date.now(),
		requestedProjectIds,
		existingProjectIds,
		view,
		defaultBranch
	};
	initialLoad = false;
	return defer(data);
}

function linkToFirstAccessibleView(
	perspectiveDescriptor: PerspectiveViewDescriptorBase,
	context: ExtendedPerspectiveContext,
	hash: NavigationHash
): string {
	const accessibleViewDescriptors = perspectiveDescriptor.getAccessibleViewDescriptors(context, hash);
	if (accessibleViewDescriptors.length === 0) {
		Assertions.fail(
			`You don't have the necessary permissions to access the ${perspectiveDescriptor.perspective.displayName} perspective!`
		);
	}
	return linkTo(hash.getPerspective(), accessibleViewDescriptors[0]!);
}

async function loadDefaultBranch(viewDescriptor: ViewDescriptor, existingProjectIds: string[]) {
	let defaultBranch: string | null = null;
	if (viewDescriptor.requiresProject) {
		defaultBranch = await getDefaultBranchForProjects(existingProjectIds).fetch();
	}
	return defaultBranch;
}

async function getRedirectLocation(
	viewDescriptor: ViewDescriptor,
	hash: NavigationHash,
	existingProjectIds: string[],
	projectId: null | string
) {
	const providesTimetravel = viewDescriptor.timeTravel !== undefined;
	const [defaultBranch, commitInfo] = await Promise.all([
		loadDefaultBranch(viewDescriptor, existingProjectIds),
		determineAndValidateCommits(hash, existingProjectIds, providesTimetravel)
	]);

	const { commitFromHash, commitFromStorage, branchFromHashExists, branchFromStorageExists } = commitInfo;

	const commitToRedirectTo = getRedirectCommit(
		commitFromHash,
		branchFromHashExists,
		defaultBranch,
		branchFromStorageExists,
		commitFromStorage
	);

	let shouldRedirect = false;
	if (needsNavigationToCurrentProject(projectId, hash)) {
		// Project in navigation hash deviates from determined project
		hash.setProjectAndPath(ProjectAndUniformPath.of(projectId, ''));
		shouldRedirect = true;
	}
	if (commitToRedirectTo && providesTimetravel && existingProjectIds.length > 0) {
		hash.setCommit(commitToRedirectTo);
		shouldRedirect = true;
	}
	return { defaultBranch, shouldRedirect };
}

/**
 * Determines the projects that are shown in the current view.
 *
 * This only returns meaningful data for views that have ViewDescriptor#requireProjects. Typically, this is the
 * currently selected project. For dashboards, it is all projects that are referenced in the currently shown dashboard.
 * For other views that allow to select all projects it is all projects if "All projects" is selected (search
 * perspective).
 */
async function loadProjects(
	projectId: string | null,
	hash: NavigationHash,
	viewDescriptor: ViewDescriptor,
	context: ExtendedPerspectiveContext,
	signal: AbortSignal
): Promise<string[]> {
	const allProjects = context.getAllProjects();
	const option = context.userInfo.userOptions[EUserOptions.LAST_DASHBOARD_OPENED_BY_USER];

	if (viewDescriptor.getProjects) {
		return viewDescriptor.getProjects(projectId, hash, option, signal);
	} else if (viewDescriptor.requiresProject && projectId === null) {
		return allProjects;
	} else if (viewDescriptor.requiresProject) {
		return [projectId!];
	}
	return [];
}

function determineViewDescriptor(
	perspectiveDescriptor: PerspectiveViewDescriptorBase,
	context: ExtendedPerspectiveContext,
	hash: NavigationHash
) {
	const viewName = hash.getViewName();
	const viewDescriptor = perspectiveDescriptor.getViewDescriptor(viewName, hash.getAction() ?? undefined);
	if (viewDescriptor === null) {
		Assertions.fail(`There is no view ${viewName} in perspective ${hash.getPerspective().displayName}!`);
	}
	if (viewDescriptor.canBeAccessed && !viewDescriptor.canBeAccessed(context, hash)) {
		Assertions.fail(`You don't have the necessary permissions to access the ${viewDescriptor.name} view!`);
	}
	return viewDescriptor;
}

/**
 * Determines whether a navigation to the given project is needed in case the history token points to another project or
 * no project is set yet.
 */
function needsNavigationToCurrentProject(projectId: string | null, hash: NavigationHash): boolean {
	return hash.getProject() !== (projectId ?? '');
}

const NO_PROJECTS_VIEW_DESCRIPTOR: ViewDescriptor = {
	anchor: '',
	name: '',
	requiresProject: true,
	hasCustomAnalysisWarning: true,
	view: () => import('./view/NoProjectsView')
};

function getEffectiveViewDescriptor(
	viewDescriptor: ViewDescriptor,
	context: ExtendedPerspectiveContext,
	hash: NavigationHash,
	requestedProjectIds: string[]
): ViewDescriptor {
	const noProjectsExist = context.getAllProjects().length === 0;
	const selectedProjectDoesNotExist = hash.getProject() !== '' && !context.projectExists(hash.getProject());
	const allRequiredProjectsInInitialAnalysis =
		ArrayUtils.intersection(context.projectsInfo.initialProjects, requestedProjectIds).length > 0 &&
		ArrayUtils.intersection(context.projectsInfo.projects, requestedProjectIds).length === 0;
	if (
		viewDescriptor.requiresProject &&
		(noProjectsExist || selectedProjectDoesNotExist || allRequiredProjectsInInitialAnalysis)
	) {
		return NO_PROJECTS_VIEW_DESCRIPTOR;
	}
	return viewDescriptor;
}

/**
 * Determines whether the view needs to navigate to a different commit. This is the case if no commit is set in the
 * navigation hash, which leads to a navigation to the last commit that was explicitly selected by the user. If a
 * deleted commit was selected we will navigate to the head revision of the default branch. The value from the hash
 * always overrides the explicitly selected commit.
 */
function getRedirectCommit(
	commitFromHash: UnresolvedCommitDescriptor | null,
	branchFromHashExists: boolean,
	defaultBranch: string | null,
	branchFromStorageExists: boolean,
	commitFromStorage: UnresolvedCommitDescriptor | null
): UnresolvedCommitDescriptor | null | undefined {
	const hasCommitInHash = commitFromHash !== null;
	if (
		commitFromHash != null &&
		((branchFromHashExists && !commitFromHash.isDefaultBranch()) ||
			commitFromHash.getBranchName() === defaultBranch)
	) {
		// If either a concrete existing branch is set or the default branch is set, which might not exist if there are
		// no commits in the project, no redirect is needed
		return undefined;
	} else if (branchFromHashExists) {
		// In the case that t=1234 branchFromHashExists will be true because the default branch is there
		// We want to keep the timestamp, but make the default branch explicit
		return UnresolvedCommitDescriptor.withExplicitDefaultBranch(commitFromHash, defaultBranch);
	} else if (!hasCommitInHash && branchFromStorageExists) {
		return commitFromStorage;
	}
	// Branch from commit in hash no longer exists (takes precedence over the commit from storage) or
	// no stored commit and an invalid commit in the hash -> load default branch on HEAD.
	return UnresolvedCommitDescriptor.latestOnBranch(defaultBranch);
}

/**
 * Determines whether the view needs to navigate to a different commit. This is the case if no commit is set in the
 * navigation hash, which leads to a navigation to the last commit that was explicitly selected by the user. If a
 * deleted commit was selected we will navigate to the head revision of the default branch. The value from the hash
 * always overrides the explicitly selected commit.
 */
async function determineAndValidateCommits(
	hash: NavigationHash,
	projectIds: string[],
	shouldValidateCommit: boolean
): Promise<{
	commitFromHash: UnresolvedCommitDescriptor | null;
	commitFromStorage: UnresolvedCommitDescriptor | null;
	branchFromHashExists: boolean;
	branchFromStorageExists: boolean;
}> {
	const commitFromHash = hash.getCommit();
	const commitFromStorage = TimetravelUtils.getLastSelectedCommitFromStorage();
	let branchFromHashExists = false;
	let branchFromStorageExists = false;
	const branchFromStorageIsDefaultMarker =
		commitFromStorage?.getBranchName() === TimetravelUtils.COMMIT_STORAGE_DEFAULT_VALUE;
	if (shouldValidateCommit) {
		[branchFromHashExists, branchFromStorageExists] = await branchesExistOnServer(
			projectIds,
			commitFromHash,
			branchFromStorageIsDefaultMarker ? null : commitFromStorage
		);

		if (!branchFromStorageExists && !branchFromStorageIsDefaultMarker) {
			TimetravelUtils.setCurrentCommitString(TimetravelUtils.COMMIT_STORAGE_DEFAULT_VALUE);
		}

		// For new tabs that were accessed e.g. from an external link or where a link was copy/pasted we want
		// to keep the commit from the URL.
		if (branchFromHashExists && commitFromHash != null && window.history.length <= 2 && initialLoad) {
			TimetravelUtils.setCurrentCommit(commitFromHash);
		}
	}

	if (commitFromStorage !== null && branchFromStorageIsDefaultMarker) {
		commitFromStorage.branchName = null;
		branchFromStorageExists = true;
	}

	return {
		commitFromHash,
		commitFromStorage,
		branchFromHashExists,
		branchFromStorageExists
	};
}

/**
 * Determines if the branches from the given commits exist for the current project.
 *
 * @returns Whether the branches exist for the current project.
 */
async function branchesExistOnServer(
	projectIds: string[],
	commit1: UnresolvedCommitDescriptor | null,
	commit2: UnresolvedCommitDescriptor | null
): Promise<[boolean, boolean]> {
	if (commit1 === null && commit2 === null) {
		// Shortcut without a request to the server.
		return [false, false];
	}

	const commits = [commit1, commit2] as const;
	const branchesToLookup = commits
		.map(commit => {
			if (commit !== null) {
				return commit.getBranchName();
			}
			return null;
		})
		.filter(branch => branch !== null);

	const existingBranchesInfo = await getFilteredBranchesInfoForProject(projectIds, branchesToLookup);
	return commits.map(commit => {
		if (commit !== null) {
			const branchName = commit.getBranchName();
			if (branchName === null) {
				// Default branch
				return true;
			}
			return (
				existingBranchesInfo.liveBranches.includes(branchName) ||
				existingBranchesInfo.deletedBranches.includes(branchName) ||
				existingBranchesInfo.anonymousBranches.includes(branchName) ||
				existingBranchesInfo.virtualBranches.includes(branchName)
			);
		}
		return false;
	}) as [boolean, boolean];
}

/**
 * Returns the branches that are contained in the project as {@link BranchesInfo}.
 *
 * @param branches The branches that should be checked. If this is an empty list all branches will be returned.
 */
function getFilteredBranchesInfoForProject(
	projectIds: string[],
	branches: Array<string | null>
): Promise<BranchesInfo> {
	const params = { projects: projectIds, 'exact-filter': branches.filter(branch => branch != null) };
	return QUERY.getGlobalBranchesGetRequest(params).fetch();
}
