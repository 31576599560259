import type { BrowserEvent } from 'ts-closure-library/lib/events/browserevent';
import type { CallbackWithPromise } from 'ts/base/Callback';
import { openKeyboardShortcutsModal } from 'ts/base/scaffolding/KeyboardShortcutsModal';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { UIUtils } from 'ts/commons/UIUtils';

/** An entry in the keyboard shortcut registry. */
export type KeyboardShortcut = {
	name: string;
	handler: CallbackWithPromise<KeyboardEvent>;
	shortcut: string;
	preventDefault: boolean;
};

/** Central registry for keyboard shortcuts. */
export class KeyboardShortcutRegistry {
	/** A string which is either 'Cmd' (on macOS) and 'Ctrl' on other systems. */
	public static readonly CMD_CTRL = navigator.userAgent.includes('Mac OS') ? 'Cmd' : 'Ctrl';
	/** Keyboard shortcut to open the issue perspective. */
	public static OPEN_ISSUE_PERSPECTIVE_SHORTCUT = 'I';

	/** Meta keys that are used in combination with other keys */
	public static META_KEYS = ['ALT', 'CTRL', 'CMD', 'SHIFT'];

	/** Mapping from key combinations to named handlers. */
	public readonly keyHandlers = new Map<string, KeyboardShortcut>();

	/** Singleton instance of the keyboard shortcut registry. */
	public static readonly INSTANCE = new KeyboardShortcutRegistry();
	private shortcutModalIsShown = false;

	public constructor() {
		this.registerShortcut('?', 'Display shortcut key list for current view', () => this.showHelp());
	}

	/** Central handler for the key event. */
	public handleKeyDown(event: KeyboardEvent): void {
		// Do not activate key bindings when in input fields
		if (UIUtils.isInputLikeEventTarget(event)) {
			return;
		}
		const prettyName = KeyboardShortcutRegistry.createPrettyName(event);
		const handler = this.keyHandlers.get(prettyName);
		if (handler != null) {
			if (handler.preventDefault) {
				event.preventDefault();
			}
			handler.handler(event);
		}
	}

	/** Creates a more pretty (readable) name for the key combination of a keyboard event. */
	private static createPrettyName(event: KeyboardEvent): string {
		const mainKey = event.key.toUpperCase();
		if (mainKey < 'A' || mainKey > 'Z') {
			// Ignore modifiers for non-alpha characters, as they might be not
			// reachable without modifiers.
			return mainKey;
		}

		const test: Record<string, BrowserEvent> = {};
		const values = Object.values(test);
		values.toString();

		let modifiers = '';
		if (event.altKey) {
			modifiers += 'Alt ';
		}
		if (event.ctrlKey) {
			modifiers += 'Ctrl ';
		}
		if (event.shiftKey) {
			modifiers += 'Shift ';
		}
		if (event.metaKey) {
			modifiers += 'Cmd ';
		}
		return modifiers + mainKey;
	}

	/**
	 * Registers a shortcut to the registry.
	 *
	 * @param shortcut A shortcut description, consisting of the optional modifiers "Alt", "Ctrl", "Shift" and an
	 *   uppercase character. The modifiers must occur in this order (if they occur). All parts have to be separated by
	 *   a single space. A valid example is "Ctrl Shift H".
	 * @param label The label used in the help dialog.
	 * @param callback The callback triggered by the shortcut.
	 * @param preventDefault If this is set to <code>false</code>, event.preventDefault() will not be called, which
	 *   means the event can be also be caught somewhere else. (e.g. if multiple components use the same shortcut). The
	 *   default behavior is to 'swallow' the event.
	 * @returns Returns a function that allows to de-register the shortcut
	 */
	public registerShortcut(
		shortcut: string,
		label: string,
		callback: CallbackWithPromise<KeyboardEvent>,
		preventDefault = true
	): () => void {
		this.keyHandlers.set(shortcut, {
			name: label,
			handler: callback,
			shortcut,
			preventDefault
		});
		return () => this.keyHandlers.delete(shortcut);
	}

	/** Shows help on registered shortcuts. */
	public showHelp(): void {
		if (this.shortcutModalIsShown) {
			return;
		}
		this.shortcutModalIsShown = true;
		const shortcuts = Array.from(this.keyHandlers.values());
		ArrayUtils.sortBy(shortcuts, shortcut => StringUtils.reverse(shortcut.shortcut));
		openKeyboardShortcutsModal(this.combineMetaKeys(shortcuts), () => {
			this.shortcutModalIsShown = false;
		});
	}

	/**
	 * Combines shortcut strings with the same name (functionality). If meta keys are used they are joined with a
	 * '/'-separator and followed by non-meta keys
	 */
	private combineMetaKeys(shortcuts: KeyboardShortcut[]): KeyboardShortcut[] {
		return shortcuts.reduce((acc, curr) => {
			const found = acc.find(item => item.name === curr.name);

			if (found && curr.shortcut.split(' ').length >= 2) {
				const shortcutSet = Array.from(
					new Set(found.shortcut.split(' ').slice(0, -1).concat(curr.shortcut.split(' ')))
				);
				found.shortcut = shortcutSet
					.filter(shortcut => KeyboardShortcutRegistry.META_KEYS.includes(shortcut.toUpperCase()))
					.join('/');
				/* Assuming there is only one non-meta key at the end */
				found.shortcut = found.shortcut.concat(` ${shortcutSet[shortcutSet.length - 1]!}`);
			} else {
				acc.push({ ...curr });
			}

			return acc;
		}, [] as KeyboardShortcut[]);
	}

	/** Triggers the handler of the given shortcut if registered. */
	public triggerShortcutHandler(shortcut: string) {
		this.keyHandlers.get(shortcut)?.handler({} as KeyboardEvent);
	}
}
