import type { JSX } from 'react';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import type { License } from 'typedefs/License';

/** Warns the user when there is no valid license or the license is expired/about to expire. */
export function useLicenseWarning(): JSX.Element | null {
	const teamscaleInfo = useTeamscaleInfo();
	const userInfo = usePerspectiveContext().userInfo;
	const license = wrapLicenseIfExists(teamscaleInfo.licenseInfo.license);
	if (!license) {
		return <GlobalWarning message="No valid license" severity="error" id="license-warning" />;
	} else if (license.expired) {
		return <GlobalWarning message={`License expired ${license.validTo}`} severity="error" id="license-warning" />;
	} else if (userInfo.isAdmin && license.expiresSoon) {
		return <GlobalWarning message={`License expires ${license.validTo}`} severity="warning" id="license-warning" />;
	} else {
		return null;
	}
}

/** Extended version of License. */
export type ExtendedLicense = License & {
	numUsersFormatted: string;
};

/** Extends the license with human-readable fields. */
export function wrapLicenseIfExists(license: License | null | undefined): ExtendedLicense | null {
	if (license !== undefined && license !== null) {
		return wrapLicense(license);
	}
	return null;
}

function wrapLicense(license: License): ExtendedLicense {
	let numUsersFormatted = String(license.numUsers);
	if (license.numUsers > 99999999) {
		numUsersFormatted = 'Unlimited';
	}
	return { ...license, numUsersFormatted };
}
